import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import { CLIENT_LINKS } from '../utils/LINKS'
// import authReducer from './AuthReducer'

const LoginMessage = () => {

    return (    
        <div>
            <span>
                You are not authenticated or your session has expired.
            </span>
            <span> <Link to={CLIENT_LINKS.login.url} >Log in</Link></span>
        </div>
    )
}     


const authReducer = (state, action) => {
    // console.log('fired switch')
  switch (action.type) {
      case "LOGIN_START":
        // console.log('fired login start')
          return {
              ...state,
            //   user: null,
              isLoading: true,
              error: "",
          }
      case "LOGIN_SUCCESS":
        // console.log('fired login success')
        // console.log(state, action)
          return {
            ...state,
              user: action.payload,
              isLoading: false,
              error:  "",
          }
      case "LOGIN_FAILURE":
        // console.log('fired login failure')
          return {
            //this place is fragile
            ...state,
                user: null,
                // user: state.user === null ? '' : state.user ,
              isLoading: false,
              error: action.payload,
          }
      case "LOGOUT":
          return {
            ...state,
              user: null,
              isLoading: false,
              error: false,
          }

          
      case "UPDATE_START":
          return {
              ...state,
              isLoading:true
          }
      case "UPDATE_SUCCESS":
          return {
                ...state,
              user: action.payload,
              isLoading: false,
              error:  false,
          }
      case "UPDATE_FAILURE":
          return {
             ...state,
              user: state.user,
              isLoading: false,
              error: true,
          }
    
    case "REGISTER_START":
    return {
        ...state,
        // user: null,
        isLoading: true,
        error: '',
    }
    case "REGISTER_SUCCESS":
        // console.log(action.payload)
        return {
            // user: {a:2},
            ...state,
            user: action.payload, 
            isLoading: false,
            error:  '',
        }
    case "REGISTER_FAILURE":
        return {
            ...state,
            user: null,
            isLoading: false,
            error: action.payload,
        }
    case "SET_DEFAULT_AUTH_MONITOR_SESSION":
        return {
            ...state,
            authMonitorStates : {
                ...state.authMonitorStates,
                isAuthenticated: true,
                snackbarVisible: false,
                snackbarMessage: "You are authenticated. You will be redirected to the home page",
                snackbarDuration: 5000,
                snackbarSeverity: 'success',
            }
        }
    case "VALIDATE_AUTH_MONITOR_SESSION":
        return {
            ...state,
            authMonitorStates : {
                ...state.authMonitorStates,
                isAuthenticated: true,
                snackbarVisible: false,
                snackbarMessage: "You are authenticated",
                snackbarDuration: 5000,
                snackbarSeverity: 'success',
            }
        }
    case "INVALIDATE_AUTH_MONITOR_SESSION":
        return {
            ...state,
            authMonitorStates : {
                ...state.authMonitorStates,
                isAuthenticated: false,
                snackbarVisible: true,
                snackbarMessage: "You are not authenticated or your session has expired.",
                snackbarDuration: 5000,
                snackbarSeverity: 'info',
            }
        }
    case "TOGGLE_AUTH_MONITOR_SESSION_SNACKBAR":
        return {
            ...state,
            authMonitorStates : {
                ...state.authMonitorStates,
                snackbarVisible: !state.authMonitorStates.snackbarVisible,

            }
        }
    default:
        return state;
      
  }
}

const initialAuthMonitorStates =  {
    isAuthenticated: true,
    snackbarVisible: false,
    // snackbarMessage: "You are authenticated. You will be redirected to the home page",
    snackbarMessage: "Hello, user",
    snackbarDuration: 5000,
    snackbarSeverity: 'info',
}

const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem("user")) || null,
    authMonitorStates: JSON.parse(localStorage.getItem("authMonitorStates")) || initialAuthMonitorStates,
    isLoading: false,
    error: false,

    // vento: '53222'
}
export const AuthContext = createContext(INITIAL_STATE)


export const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(authReducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.user))
    localStorage.setItem ( "authMonitorStates", JSON.stringify(state.authMonitorStates) )
}, [state])

  return (
    <AuthContext.Provider
    value={{
        user: state.user,
        isLoading: state.isLoading,
        error:state.error,
        authMonitorStates: state.authMonitorStates,
        dispatch,
    }}>
        {/* {!state.isLoading && children} */}
        {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthContext