import { ArrowBackIos, ArrowForwardIos, ArrowLeftOutlined, ArrowRightAltOutlined, KeyboardDoubleArrowLeftOutlined } from '@mui/icons-material';
import './reusableHorizontalScrollMenu.scss'
// HorizontalScrollMenu.js

import React, { useEffect, useRef, useState } from 'react';
// import './HorizontalScrollMenu.css'; // Create a CSS file for styling

function ReusableHorizontalScrollMenu({
  array, 
  currentItem, 
  handleSetCurrentItem, 
  itemStyle, 
  button_left, 
  button_right, 
  styleGap}) {
  const [scrollPosition, setScrollPosition] = useState(0);



  const array_item_Ref = useRef(null)

  const INITIAL_COMPONENT_STATE = {
    right_button_visible: true,
    left_button_visible: true,
  }
  const [componentState, setComponentState] = useState({
    right_button_visible: true,
    left_button_visible: false, //on purpose
  })
  

  const [menuItemSize, setMenuItemSize] = useState({
    width: 0,
    height: 0
  })
  useEffect(() => {
    // first
    const updateElementSize = () => {
      if (array_item_Ref?.current) {
        // Get the DOM node of the element
        const element = array_item_Ref.current;
    
        // Use the `offsetWidth` and `offsetHeight` properties to get the width and height
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        // console.log(width, height)

        setMenuItemSize((prev) => ({
          height: height,
          width: width
        }))
    
        // Update the state with the measured size
        // setElementWidth(width);
        // setElementHeight(height);
      }
    }
  
    updateElementSize();

    window.addEventListener('resize', updateElementSize);
    return () => {
      // second
      window.removeEventListener('resize', updateElementSize);
    }
  }, [])

  const scrollLeft = () => {
    setScrollPosition((prev) => prev + (menuItemSize.width > 0 ? menuItemSize.width : 100) );
  };
  
  const scrollRight = () => {
    setScrollPosition((prev) => prev - (menuItemSize.width > 0 ? menuItemSize.width : 100));
  };

  
  const menuItemsRef = useRef(null);
  const menuContainerRef = useRef(null);
  const [disableRunEffectInitially, setDisableRunEffectInitially] = useState(0)
  useEffect(() => {
    const handleScrollVisibility = () => {
      if(disableRunEffectInitially <= 0){
        setDisableRunEffectInitially(prev => (prev + 1))
        return ''
      }

      const menuContainer = menuContainerRef.current;
      const menuItems = menuItemsRef.current;

      if (menuContainer && menuItems) {
        const containerRect = menuContainer.getBoundingClientRect();
        const itemsRect = menuItems.getBoundingClientRect();

        // console.log(containerRect.left, itemsRect.left)
        // console.log(containerRect.right, itemsRect.right)
        setComponentState(prev =>({...INITIAL_COMPONENT_STATE}))

        // Check if the items are completely outside the container on the left side
        if (itemsRect.left > containerRect.left) {
          // console.log('Items are not visible on the left');
          return setComponentState(prev =>({...prev, left_button_visible: false}))

        }

        // Check if the items are completely outside the container on the right side
        if (itemsRect.right < containerRect.right) {
          // console.log('Items are not visible on the right');
          return setComponentState(prev =>({...prev, right_button_visible:false}))

        }

        return setComponentState(prev =>({...INITIAL_COMPONENT_STATE}))

      }
    };
    handleScrollVisibility()

    // window.addEventListener('scroll', handleScrollVisibility);

    return () => {
      // window.removeEventListener('scroll', handleScrollVisibility);
    };
  }, [scrollPosition]);

  const arrays = [
    {
      id: 1,
      text: 'All',
      simplified: 'all'
    },
    {
      id: 2,
      text: 'Approved',
      simplified: 'approved'
    },
    {
      id: 3,
      text: 'Approved',
      simplified: 'approved'
    },
    {
      id: 4,
      text: 'Approved',
      simplified: 'approved'
    },
    {
      id: 5,
      text: 'Approved',
      simplified: 'approved'
    },
    {
      id: 6,
      text: 'Approved',
      simplified: 'approved'
    },
    {
      id: 7,
      text: 'Approved',
      simplified: 'approved'
    }, 
    
  ]

// console.log(currentItem)
  return (
    <div className="horizontal-scroll-menu">
      <div  
      style={{
        display: componentState.left_button_visible ? 'flex' : 'none'
      }}
      onClick={scrollLeft} 
      className="horizontal-scroll-menu-left-button">
        {
          button_left ||
          <ArrowBackIos fontSize='1rem' />
        }
      </div>
      <div 
      ref={menuContainerRef}
      className="menu-container" >
        <div 
        ref={menuItemsRef}
        className="menu-items"
         style={{ 
          transform: `translateX(${scrollPosition}px)`,
          gap: styleGap || '3rem'
        }}>
            {
            array?.length > 0 &&
            array.map((item, index) => (
                <span 
                onClick={() => handleSetCurrentItem(item)}
                style={itemStyle}
                ref={array_item_Ref} 
                className={`menu-item ${currentItem.id === item.id && 'active'}`}
                key={index}>
                 {item.text}
                </span>
            )) 
            }
            {
            array?.length <= 0 &&
            'No array supplied'
            }
          {/* Add more items as needed */}
        </div>
      </div>
      <div 
      style={{
        display: componentState.right_button_visible ? 'flex' : 'none'
      }} 
      onClick={scrollRight}
      className="horizontal-scroll-menu-right-button">
        {
          button_right ||
          <ArrowForwardIos fontSize='1rem' />
        }
      </div>
    </div>
  );
}

export default ReusableHorizontalScrollMenu
