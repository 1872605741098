// import React from 'react'
import axios from 'axios'
import { useAuth } from '../../../contexts/AuthContext'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility'
import './notificationsPage.scss'
import { useQuery } from '@tanstack/react-query'




import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Add, AddCircle, AddCircleOutline, AdminPanelSettings, ArrowLeft, CalendarMonth, Close, Delete, DeleteOutline, DriveFileRenameOutline, FilterAlt, FitScreenOutlined, HighlightOff, Keyboard, PlayArrow, Search, Settings, Title, ZoomIn, ZoomOut } from '@mui/icons-material'
import { useOtherContext } from '../../../contexts/OtherContext'
import { scrollToTopInstantUtil } from '../../../utils/Utils'
import ReusableThesisItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import ReusablePaginationComponent from '../../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent'
import { ReusableDraggableDialog } from '../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog'
import { ReusablePageSettingsDialogClient } from '../../../components/reusables/ReusablePageSettingsDialog/ReusablePageSettingsDialogClient'

const sortOptions = {


  latest_modified: 'Recent',
  oldest_modified: 'Oldest',


};


const NotificationItem = ({passed_notification}) => {
  // console.log(passed_notification)

  const notificationTime = passed_notification?.updatedAt && ( new Date(passed_notification?.updatedAt)?.toUTCString() )

  return (
    <div className='notification-item'>
      <div className='notification-item-message'>{passed_notification?.message}</div>
      <div className='notification-item-add-info'>{passed_notification?.additional_info || ''}</div>
      <div className='notification-item-time'>
        <span>
          {notificationTime }
        </span>
      </div>
    </div>
  )
}


const NotificationsPage = () => {
  UseHideSidebar()
  const {user} = useAuth()

  // UseShowSidebar()

  // Use the useLocation hook to access the current URL
  const location = useLocation();
  const {search_state, dummy_state, dispatch, thesis_search_url, search_state_session, sidebar_width} = useOtherContext()

      const [componentState, setComponentState] = useState({
    isPageSettingsOpened: false
  })
    const togglePageSettingsUI = () => {
    setComponentState(prev => ({
      ...prev,
      isPageSettingsOpened: !prev.isPageSettingsOpened
    }))
  }


  const [queryState, setQueryState] = useState({
    // baseURL: `/users/find_all_users_advanced_search?itemsPerPage`,
    // itemsPerPage: admin_results_page_items,
    // current_page: admin_current_page,
    page_size: search_state?.req_query_page_size || 10,
    page_num: search_state_session?.current_page || 1,
    sort: 'latest_modified' ,
    // searchBy: '',
    // input_box_value: ''

  })

  const handleQueryState = (e) => {
    const {name, value} = e.target
    setQueryState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const [currentPage, setCurrentPage] = useState(1);

  const fetchTheses = async () => {
    const query_params = {
      page_size : search_state?.req_query_page_size || 10,
      page_num: search_state_session?.current_page || 1,
      user_id: user?._id || '',
      sort: queryState.sort

    }
    let queryParamsString = new URLSearchParams(query_params).toString();

    const response = await axiosInstance.get(`/simple_notification_route/find_notifications_advanced_search?${queryParamsString}`, {});

    // console.log(queryParamsString)

    // console.log(response.data.message)
    return response.data.message;

  };

  const { data:fetched_thesis, refetch, isLoading: isLoadingResults } = useQuery({
    queryKey: ['thesisListResultsPage'],
    queryFn: () =>
      fetchTheses()
  }
  )
  if(!isLoadingResults){
    // console.log(fetched_thesis?.results?.length)
  }

  // console.log(aim)

  const handlePageChange = (newPage) => {
    // Handle the page change logic here
    // console.log(newPage)
    // dispatch()
    setCurrentPage(newPage); //not useful. we are using the ls context now
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: newPage
    })
    // refetch()
  };

  useEffect(() => {
    const runRefetchAndScroll = async () => {
      try {
        // await refetch()
        refetch()
        scrollToTopInstantUtil()
      } 
      catch (error) {
        console.log(error)
      }

    }
    runRefetchAndScroll()
    
  
    return () => {
    }
  }, [
    search_state_session?.current_page, 
    search_state?.req_query_page_size, 
    search_state_session?.force_update,
    queryState.sort
  ])

  useEffect(() => {
    //set it in the session
    if(fetched_thesis){
      dispatch({
        type: 'SET_MAX_PAGE',
        payload: fetched_thesis?.total_pages || 1
      })
    }
  }, [fetched_thesis])
  

console.log(fetched_thesis)
  //fetch the title using react query
  //if the paremValue is null then search the db
  //get the fiter from the url params

  // const location = useLocation()
  useEffect(() => {
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
  }, [location.pathname])
  

  
  const initialSelected = (state_value, value_passed) => {
    const check_passed = (state_value === value_passed)
    return check_passed
  }


  
  return (
    <div className='notifications-page'>

      <div className='notifications-page-inner'>
        <div style={{
          fontSize: '1.1rem',
          fontWeight: 200,
          margin: '1rem 0rem'
        }}>Notifications</div>
        <div className='advanced-results-page-results'>
            <div className='advanced-results-page-results-inner'>
              <div className='advanced-results-page-items'>
              {
              fetched_thesis?.notifications?.length > 0 &&
              fetched_thesis?.notifications?.map((notification, index) => (
                <NotificationItem
                key={index} 
                passed_notification={notification} 
                authUser={user} />
              ))
              }
              {
              fetched_thesis?.notifications?.length <= 0 &&
              <p>
                No documents matched your query
              </p>
              }
              </div>
            </div>
        </div>
        <div className='adv-srp-pagination-component'
                style={{
                  width: `${100}%`
                }}>
          <div>

          <ReusablePaginationComponent
          // currentPage={currentPage} 
          currentPage={search_state_session.current_page}
          totalPages={fetched_thesis?.total_pages} 
          onChange={handlePageChange} />
          </div>
          <div className='amappc-bottom-right'>
                <span>
                  <select
                    className='sort-results-by-select'
                    // id='sort'
                    name='sort'
                    onChange={handleQueryState}
                    // defaultValue={sortOptions.mo}
                    // value={filtersState.sortBy}
                    // onChange={handleSortByChange}
                  >
                    {
                    // note that we passed, key ie object key, instead of object property/value
                    Object.entries(sortOptions).map(([value, label]) => (
                    <option 
                    selected={initialSelected(queryState.sort, value)} 
                    key={value} value={value}>
                        {label}
                    </option>
                    ))}
                  </select>
                </span>
                <span
                onClick={togglePageSettingsUI}
                className='amappc-bottom-right-settings-icon-container'
                >
                  <Settings fontSize='1rem' />
                </span>
                <ReusableDraggableDialog
                header={"Page Settings"}
                main_body={
                  <ReusablePageSettingsDialogClient
                    page_context={useOtherContext()} 
                    setIsModalOpened={togglePageSettingsUI}
                    isModalOpened={componentState.isPageSettingsOpened}
                  />
                } 
                isOpened={componentState.isPageSettingsOpened}
                setIsOpened={togglePageSettingsUI}
                />
            </div>
        </div>
      </div>
    </div>
  )
}



export default NotificationsPage
// export default ReusableFilterResultsBox