import React, { useEffect, useState } from 'react'

import './aboutSupervisorPage.scss'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import ReusableThesisItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import { capitalizeFirstLetter_Util } from '../../../utils/Utils'
import { Email, LinkedIn, Twitter } from '@mui/icons-material'


const UserItem = ({passed_user}) => {

    const nameOfUser = ( passed_user?.first_name ? capitalizeFirstLetter_Util(passed_user?.first_name)+ ' ' : '') +  capitalizeFirstLetter_Util(passed_user?.last_name) 

    return (
        <div className='about-supervisor-page-user-item'>
            <div> {nameOfUser} </div>
            <div> 
                <span> { (passed_user?.department) || ''}  </span>
                <span> { (passed_user?.faculty ? ', '+passed_user?.faculty : '') || ''}  </span>
                <span>  {(passed_user?.institution_abbreviation ? ', '+passed_user?.institution_abbreviation  : '' ) || ''}  </span>
            </div>
        </div>
    )
}

const KeywordItem = ({passed_keyword}) => {

    return (
        <div 
        // className='ctpcii-span'
        className='about-supervisor-page-keyword-item'
        >
            <span> {passed_keyword.keyword} {' '} </span>
            <span> : {passed_keyword.count} {' counts'} </span>
        </div>
    )
}

const AboutSupervisorPage = ({passed_supervisor}) => {
    // UseHideSidebar()
    // console.log(passed_supervisor)

    const [componentState, setComponentState] = useState({
        ...passed_supervisor,
        lecturer_image: '',
        // associatedSupervisorDetails
    })

    const setLecturerImage = (value_passed) => {
        setComponentState(prev => ({...prev, lecturer_image: value_passed}))
    }
    useEffect(() => {
        
        const fetchLecturerImage = async () => {
            //make api call
            // const res = await axios.get('/apicall')
            try{
                // setLecturerImage(res.data)
            }
            catch{
                // console.log(error)
                //notify it in the snackbar or not
            }
        }

        fetchLecturerImage()
    
    
      return () => {
        // second
      }
    }, [])
    

    //fetch the image of the supervisor with a use Effect

    //dont fetch any other thing with usequery. The page doesnt have to be the most current updated in seconds

    const fetchSupervisor = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })
    
        const c_url= `/theses/find_theses_and_users_connected_to_supervisor?supervisor_list=${passed_supervisor?._id}`
        // const c_url= `/theses/find_theses_and_users_connected_to_supervisor?supervisor_list=`

        const response = await axiosInstance.get(c_url);
        console.log(response.data.message)
        return response.data.message;
    };
    const { isFetching: isFetchingRandomTheses, isLoading: isLoadingRandomTheses, error, data:fetchedAssociatedSupervisorDetails, refetch:refetchRandomTheses} = useQuery({
    queryKey: ['view_all_thesis_paginatedsadgfdf'],
    queryFn: () =>
        fetchSupervisor()
    })


    useEffect(() => {
      setComponentState( prev => ({...prev, associatedSupervisorDetails: fetchedAssociatedSupervisorDetails}))
    

    }, [fetchedAssociatedSupervisorDetails])
    
    

  
    const fullNameWithTitle = (passed_supervisor?.supervisor_title + ' ' + passed_supervisor?.supervisor_name) || ''
    const affiliatedInstitution = passed_supervisor?.institution || ''
    // const 

    const handleSocialClick = (social_site) => {
        // console.log('hi')

        if(!social_site){
            return alert('Not available, sorry')

        }

        //else create a new 
    }
    
    return (
    <div className='about-supervisor-page'>
        
        <div className='about-supervisor-page-inner'>
            <div className='about-supervisor-name'>
                <span> {fullNameWithTitle}
                </span>
                    
            </div>
            {
            componentState?.lecturer_image &&
            <section className='about-supervisor-image-section'>
                Image in center
                <div className='about-supervisor-image-container'>
                    {/* <image  className="about-supervisor-image-itself" src={componentState.lecturer_image} of dr gambo with fig caption/> */}
                </div>
            </section>
            }
            <section className='about-supervisor-about'>
                <p>
                    { passed_supervisor?.supervisor_about}
                </p>
            </section>
            <section className='about-supervisor-socials'>
                <div>Socials:</div>
                <div className='about-supervisor-socials-buttons'>
                    <span onClick={ ()=> handleSocialClick()}> <LinkedIn fontSize='1rem' /> </span>
                    <span onClick={ ()=> handleSocialClick()}> <Email fontSize='1rem' /> </span>
                    <span onClick={ ()=> handleSocialClick()}> <Twitter fontSize='1rem' /> </span>
                </div>
            </section>
            <section className='about-supervisor-others'>
                {/* <div  style={{cursor: 'pointer'}}>
                    Common Keywords associated with this supervisor: 
                </div> */}
                <details  
                open
                className='about-supervisor-keywords-details'
                style={{cursor: 'pointer'}}>
                    <summary> Common keywords associated with this supervisor</summary>
                    <div className='about-supervisor-keywords-container'>
                    {
                    fetchedAssociatedSupervisorDetails?.mostFrequentKeywords?.map(keyword => (
                        <KeywordItem passed_keyword={keyword} />
                    ))
                    }
                    </div>
                </details>
                <details className='about-supervisor-theses-details' style={{cursor: 'pointer'}}>
                    <summary>Partial list of theses supervised by  
                        { ' ' + passed_supervisor?.supervisor_title + ' '} 
                        {passed_supervisor?.supervisor_name}</summary>
                    <div className='about-supervisor-theses-container'> 
                    {
                    fetchedAssociatedSupervisorDetails?.theses?.map(thesis => (
                        <ReusableThesisItem passed_thesis={thesis} />
                    ))
                    }
                    {/* fetch list of thesis supervised by the supervosr */}
                    </div>
                    {/* render it as a clickable thesis item */}
                </details>
                <details className='about-supervisor-users-details' style={{cursor: 'pointer'}}>
                    <summary> Partial list of students supervised by 
                        { ' ' + passed_supervisor?.supervisor_title + ' '} 
                        {passed_supervisor?.supervisor_name} </summary>
                    <div className='about-supervisor-users-container'>
                    {
                    fetchedAssociatedSupervisorDetails?.associatedUsers?.map(user => (
                        <UserItem passed_user={user} />
                    ))
                    }
                    </div>
                </details>
            </section>
        </div>
    </div>
  )
}

export default AboutSupervisorPage