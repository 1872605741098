import './logoutPage.scss'


import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute'
import { useNavToPrev } from '../../../hooks/useNavToPrev'

import { CheckCircle, CheckCircleOutline, Lock, Mail, MailOutline} from '@mui/icons-material';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useContext, useState } from 'react';
import {useAuth} from '../../../contexts/AuthContext';
import axios from 'axios'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { UseHideTopbar, UseShowTopbar } from '../../../hooks/UseTopbarVisibility';
import { API_LINKS, CLIENT_LINKS } from '../../../utils/LINKS';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { LinearProgress } from '@mui/material';



const LogoutPage = () => {
  UseHideSidebar()
  UseShowTopbar()
  // UseHideTopbar()
  const navigate = useNavigate()


  const {dispatch} = useAuth()


  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    isSpinnerActive: false,

    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }

  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }


  const handleLogoutSingleDevice = async (e) => {
    // console.log('hi')
    e.preventDefault()

    try{
      hideSnackbar()
      disableButton()
      const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })


      const res = await axiosInstance.post('/auth/logout') //your cocokies will automatically be sent

      dispatch({
          type: 'LOGOUT', 
      })

      setSnackbarMessage('Logout successful' )
      setSnackbarSeverity("info")
      showSnackbar()
      return navigate(CLIENT_LINKS.login.url)
    }
    catch(error){

      setSnackbarMessage(`Failed to logout. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()
    }
    finally{
      enableButton()
    }
  }

  const handleLogoutAllDevices = async (e) => {
    // console.log('hi')
    e.preventDefault()

    try{
      hideSnackbar()
      disableButton()
      const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })


      const res = await axiosInstance.post('/auth/logoutAllDevices') //your cocokies will automatically be sent

      dispatch({
          type: 'LOGOUT', 
      })

      setSnackbarMessage('Logout successful' )
      setSnackbarSeverity("info")
      showSnackbar()
      return navigate(CLIENT_LINKS.login.url)
    }
    catch(error){

      setSnackbarMessage(`Failed to logout. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()
    }
    finally{
      enableButton()
    }
  }

  const handleLogout = async (e, param) => {
    // console.log('hi')
    e.preventDefault()

    try{
      hideSnackbar()
      disableButton()
      const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })


      const body = {}
      if(param === 'logoutOthers'){
        body.shouldLogoutOthers = true
      }
      showSpinner()
      const res = await axiosInstance.post('/auth/logout', body) //your cocokies will automatically be sent

      dispatch({
          type: 'LOGOUT', 
      })

      setSnackbarMessage( `${res?.data?.message ||  'Logout successful' }` )
      setSnackbarSeverity("info")
      showSnackbar()

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1500); // 10000 milliseconds = 10 seconds
      });


      return navigate(CLIENT_LINKS.login.url)
    }
    catch(error){

      dispatch({
        type: 'LOGOUT', 
    })
      // setSnackbarMessage(`Failed to logout. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarMessage(`An error occurred might have occurred while logging you out. Your session may already be expired. Nevertheless, you will still be logged out`)
      setSnackbarSeverity("info")
      // toggleInterfaceVisibility('snackbar_visible')
      setSnackbarDuration(5000)
      showSnackbar()
    }
    finally{
      hideSpinner()
      dispatch({
        type: 'LOGOUT', 
      })
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 10000 milliseconds = 10 seconds
      });

      
      enableButton()
      return navigate(CLIENT_LINKS.login.url)
      
    }
  }




  const handleNavigateBack = async(e) => {
    try{
      // navigate('back')
      // navigate(-1)
      navigate(CLIENT_LINKS.home.url)

    }
    catch(error){
      navigate(CLIENT_LINKS.home.url)
    }
  }

  return (
    <div className='logout-page'>
        <ReusableSnackbarComponent 
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
        />
        {/* <img className='login-page-bg-image' src={login_bg} alt=''/> */}
        <div className='logout-page-inner'>
            <div className='logout-page-box'>
              <div className='logout-title'>Log out ?</div>
              <div className='logout-button-containers'>
                <button 
                disabled={interfaceState.button_disabled}
                 onClick={(e) => handleLogout(e, 'logoutOthers')} className='logout-button-1'>Yes(all devices)</button>
                <button 
                disabled={interfaceState.button_disabled}
                onClick={(e) => handleLogout(e)}
                className='logout-button-2'> Yes(single device)</button>
                <button 
                disabled={interfaceState.button_disabled}
                 onClick={handleNavigateBack}
                className='logout-button-3'>No</button>
              </div>
              {
                // interfaceState.isSpinnerActive &&
                <div style={{height: '0.5rem'}}>
                <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
                </div>
              }
            </div>
        </div>
    </div>
  )
}



export default LogoutPage




   