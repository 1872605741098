import './registerPage.scss'
import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute'
import { useNavToPrev } from '../../../hooks/useNavToPrev'
import { CheckCircle, CheckCircleOutline, Close, InfoOutlined, KeyOutlined, Lock, Mail, MailOutline, Person, VisibilityOutlined} from '@mui/icons-material';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useContext, useState } from 'react';
import {useAuth} from '../../../contexts/AuthContext';
import axios from 'axios'
import { UseHideSidebar, UseShowSidebar } from '../../../hooks/UseSidebarVisibility';
import { UseHideTopbar } from '../../../hooks/UseTopbarVisibility';
import {API_LINKS, CLIENT_LINKS} from '../../../utils/LINKS'
import ReusableFullscreenDialog from '../../../components/reusables/ReusableFullscreenDialog/ReusableFullscreenDialog';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { LinearProgress } from '@mui/material';
import { validateAlphabeticWithTrim_Util, validateEmail_Util, validatePassword_Util, validateSingleName_Util } from '../../../utils/Utils';
import { useWindowSize } from '../../../hooks/useWindowSize/useWindowSize';





const VerificationMailSentPage= ({credentials, open, setOpen, resendRegistration}) => {
  // console.log(passed_supervisor)
  //refetch the id  
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    isVerifyAccountInterfaceVisible: false
    // isVerifyAccountInterfaceVisible: !false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const toggleVerifyAccountVisibility = () => {
    setInterfaceState((prev) => ({ ...prev,  isVerifyAccountInterfaceVisible: !prev.isVerifyAccountInterfaceVisible  }))
  }

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }


  const handleEditForm = () => {

    setOpen(!open)
  }


  const resendVerificationMail = async (e) => {

    try{
        e.preventDefault();
        disableButton()
        if(true){
          if(!credentials.email ){
            setSnackbarMessage( "Please provide an email")
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.password ){
            setSnackbarMessage( "Please provide a password" )
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.first_name ){
            setSnackbarMessage( "Please provide a first name")
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.last_name ){
            setSnackbarMessage("Please provide a last name" )
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.enrollmentKey ){
            return alertSnackbar("Please supply an enrollment key", 'error')
            // return alert('Please fill all fields to proceed')
          }

        }

        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })
        // dispatch({type: 'REGISTER_START'})
        const res = await axiosInstance.post('/auth/register', credentials)

        alertSnackbar(`${res?.data?.message || 'A verification link has been sent to your email'}`)
        // setSnackbarMessage( "Please verify your account")
        // setSnackbarSeverity( "info")
        // showSnackbar()
        // toggleVerifyAccountVisibility()
        // navigate(CLIENT_LINKS.verify_account_page.url)
        // disableButton()
        // return '' 
    }
    catch(error){
      console.error(error)
      alertSnackbar(`${error.response?.data?.message ||  'An error occurred'}` , 'error')
      enableButton()
      // dispatch({
      //       type:'REGISTER_FAILURE', 
      //       payload:temp
      // })
    }
    finally{
      //dont enable the button unless it fails. Use css to dim the :disabled placeholder
      enableButton()
    }
  }
  

  return(
    <div className='verification-mail-sent-page'>
      <div>
        <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      </div>
      <div className='verification-mail-sent-page-inner' >
          <div className='verification-mail-sent-page-box'>
            <div className='verification-mail-sent-page-box-top'> 
              <span className='verification-mail-sent-page-box-top-icon'><CheckCircle fontSize='1rem' /> </span>
            </div>
            <div className='verification-mail-sent-page-box-info'> 
              We have sent a verification link to {credentials.email || 'you'}.
              Check your inbox or spam folder
            </div>
            <div 
            onClick={handleEditForm}
            className='verification-mail-sent-page-box-editForm'>  
              Edit form or
            </div>
            <div 
            onClick={resendVerificationMail}
            disabled={interfaceState.button_disabled}
            className='verification-mail-sent-page-box-resend'>
              Resend verification mail
            </div>
          </div>
          {/* timer will also be reflected here */}
        
      </div>
    </div>
  )
}



















const RegisterPage = () => {
  UseHideSidebar()
  UseHideTopbar()
  const navigate = useNavigate()
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    isVerifyAccountInterfaceVisible: false,
    isSpinnerActive: false,
    // isVerifyAccountInterfaceVisible: !false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const toggleVerifyAccountVisibility = () => {
    setInterfaceState((prev) => ({ ...prev,  isVerifyAccountInterfaceVisible: !prev.isVerifyAccountInterfaceVisible  }))
  }

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

  const {user, isLoading, error, dispatch,} = useAuth()
// console.log(user, 'printing out')
  const {width: userWindowWidth}  = useWindowSize()
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    passwordType: 'password',
    first_name: "",
    last_name: "",
    enrollmentKey: "",
    userWindowWidth: userWindowWidth
  })
  // console.log(credentials)

  const togglePasswordVisibility = async() => {

    if(credentials.passwordType === 'password') (
      setCredentials((prev) => ({...prev, passwordType: 'text' }))
      
      )
    if(credentials.passwordType === 'text') {
      setCredentials((prev) => ({...prev, passwordType: 'password' }))
      
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 5000); // 10000 milliseconds = 10 seconds
    });

    setCredentials((prev) => ({...prev, passwordType: 'password' }))


    
    //if it is text do nothing. This prevents us from having to disable button
  }


  const provideInputInfo = (name) => {
    // const {name, value} = e.target

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "enrollmentKey"){
      const requirements = 
      <>
        <div>You must supply an enrollment key </div>
        <div>If you do not have an enrollment key, please contact the administrator</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "password"){
      const requirements = 
      <>
      <div>Password must be at least 8 characters in length </div>
      <div> Password must contain at least one digit </div>
      <div>Password must contain at least one lowercase letter</div>
      <div>Password must contain at least one uppercase letter. </div>
      <div>Password must contain at least one special character </div>

      </>
      
      alertSnackbar(requirements)


      return ''
    }
    if(name === "first_name"){
      const requirements = 
      <>
      <div>Must not contain spaces</div>
      <div>Must not contain numbers</div>
      <div>Must not contain special characters or symbols</div>
      </>
      
      alertSnackbar(requirements)
      return ''

    }
    if(name === "last_name"){
      const requirements = 
      <>
      <div>Must not contain spaces</div>
      <div>Must not contain numbers</div>
      <div>Must not contain special characters or symbols</div>
      </>
      alertSnackbar(requirements)

      return
    }

    
  }



  const provideInputFeedback = (name) => {
    //this function runs on every state change
    //we should rather use an effect for this in the case there are many other things that are changing

    //this should be in a use effect that changes when creedntials changes
    
    // console.log(e)
    // const {name, value} = e?.target
    // const {name,value} = e?.target
    // console.log(name, value)
    // return ''
    const value = credentials[name]
    //i.e credentials.name that is the value of the password, name etc
    
    const goodInput = (
      <span 
      style={{color: 'green'}}
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> </span>)

    const badInput = (<span 
      style={{cursor: 'pointer', color: 'tomato'}}
      onClick={()=> provideInputInfo(name) }
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>)

    if(name === "email"){
      const {isValid} = validateEmail_Util(value)
      // console.log('hey')
      // console.log(isValid)
      if(isValid){
        return goodInput
      }
      
      return badInput
      
    }
    
    if(name === "password"){
      const {isValid }= validatePassword_Util(value)
      if(isValid){
        return goodInput
      }
      
      return badInput
    }
    if(name === "first_name"){
      const {isValid }= validateSingleName_Util(value)
      if(isValid){
        return goodInput
      }
      
      return badInput
    }
    if(name === "last_name"){
      const {isValid} =  validateSingleName_Util(value)
      if(isValid){
        return goodInput
      }
      
      return badInput
    }
    if(name === "enrollmentKey"){
      const isValid = credentials?.enrollmentKey?.length > 0
      // console.log('hey')
      // console.log(isValid)
      if(isValid){
        return goodInput
      }
      
      return badInput
      
    }


    // //default
    <span
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'>
    <InfoOutlined fontSize='1rem'/> </span>
  
  }

  const handleChange = (e) => {
    setCredentials((prev) => ({...prev, [e.target.name]: e.target.value}))
    // provideInputFeedback(e)
  }

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })



  const handleRegister = async (e) => {

    try{
        hideSnackbar()
        e.preventDefault();
        disableButton()
        if(true){
          if(!credentials.email ){
            setSnackbarMessage( "Please provide an email")
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.password ){
            setSnackbarMessage( "Please provide a password" )
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.first_name ){
            setSnackbarMessage( "Please provide a first name")
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials.last_name ){
            setSnackbarMessage("Please provide a last name" )
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
          }
          if(!credentials?.enrollmentKey){
            return alertSnackbar('Please supply an enrollment key', 'error')
          }
          if(!credentials?.enrollmentKey?.toString().length > 0){
            return alertSnackbar('Please supply an enrollment key', 'error')
          }

        }
        if(true){
          const {email, password, first_name, last_name} = credentials
          const {isValid: isEmailValid} = validateEmail_Util(email)
          const {isValid: isPasswordValid} = validatePassword_Util(password)
          const {isValid: isFirstNameValid} =  validateSingleName_Util(first_name)
          const {isValid: isLastNameValid} =  validateSingleName_Util(last_name)

          if(!isEmailValid){
            alertSnackbar('Email is not valid click the info button in the input box', 'error')
            return ''
          }
          if(!isPasswordValid){
            alertSnackbar('Password is not valid click the info button in the input box', 'error')
            return ''
          }
          if(!isFirstNameValid){
            alertSnackbar('First name is not valid click  the info button in the input box', 'error')
            return ''
          }
          if(!isLastNameValid){
            alertSnackbar('Last name is not valid click the info button in the input box', 'error')
            return ''
          }

        }

        // dispatch({type: 'REGISTER_START'})
        showSpinner()
        const res = await axiosInstance.post('/auth/register', credentials)
        // dispatch({
        //     // type: 'LOGIN_SUCCESS', payload: res.data
        //     type: 'REGISTER_SUCCESS', 
        //     payload: res.data.message
        // })
        // console.log(res)

        // console.log(user)
        // return
        // setSnackbarMessage( "Please verify your account")
        // setSnackbarSeverity( "info")
        // showSnackbar()
        alertSnackbar(`${res?.data?.message || 'A verification link has been sent to your email'}`)
        toggleVerifyAccountVisibility()
        // navigate(CLIENT_LINKS.verify_account_page.url)
        // disableButton()
        // return '' 
    }
    catch(error){
      console.error(error)
      setSnackbarMessage(`${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      showSnackbar()
      enableButton()
      // dispatch({
      //       type:'REGISTER_FAILURE', 
      //       payload:temp
      // })
    }
    finally{
      //dont enable the button unless it fails. Use css to dim the :disabled placeholder
      enableButton()
      hideSpinner()
    }
  }

  return (
    <div className='register-page'>
        {/* <img className='register-page-bg-image' src={login_bg} alt=''/> */}
        <div>
          <ReusableFullscreenDialog
          open={interfaceState.isVerifyAccountInterfaceVisible}
          setOpen={ toggleVerifyAccountVisibility }
          title={'Update Account'}
          action_title={'update'}
          no_preloaded_content
          // otherFunctionsPassed={finallyUpdate}
          main_body={
            <VerificationMailSentPage
              open={interfaceState.isVerifyAccountInterfaceVisible}
              setOpen={ toggleVerifyAccountVisibility }
              credentials={credentials}
            />
          }
          /> 
        </div>
        <div>
          <ReusableSnackbarComponent
          severity={interfaceState.snackbar_severity}
          open={interfaceState.snackbar_visible}  
          inner_message={interfaceState.snackbar_message} 
          setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
          autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
        </div>
        <div className='register-page-form-container'>
            <div className='register-page-form'>
                <div className='register-page-form-inner-wrapper'>
                    <div className='register-page-form-inner-wrapper-group-1'>
                        <h1 className='register-page-form-inner-wrapper-group-1-a' >Create Account / Verify Account </h1>
                        <div className='register-page-form-inner-wrapper-group-1-b' >Have an account?  
                            <Link to={CLIENT_LINKS.login.url} className='router-dom-LINK'>
                                <strong> Log in</strong>
                            </Link>
                        </div>
                    </div>
                    <div className='register-page-form-inner-wrapper-group-2'>
                        <div>
                        <label>Email</label>
                        <div className='register-page-form-inner-wrapper-group-2-a' >
                            <span className='register-page-form-inner-wrapper-group-2-a-icon-1'><Mail fontSize='1rem'/> </span>
                            <input 
                            disabled={interfaceState.button_disabled}
                            onChange={(e) => handleChange(e)}
                            name="email"
                            type='email' placeholder='' />
                            {provideInputFeedback("email")}
                        </div>
                        </div>
                        <div>
                        <label>Password</label>
                        <div className='register-page-form-inner-wrapper-group-2-b' >
                            <span className='register-page-form-inner-wrapper-group-2-b-icon-1'><Lock fontSize='1rem'/> </span>
                            <input 
                            disabled={interfaceState.button_disabled}
                            onChange={(e) => handleChange(e)}
                            name="password"
                            type= {credentials?.passwordType || 'password'} 
                            placeholder='' />
                            {
                            credentials?.password &&
                            <span 
                             onClick={togglePasswordVisibility}
                              style={{cursor: 'pointer'}}
                              className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                            </span>
                            }
                            {provideInputFeedback("password")}

                        </div>
                        </div>
                        <div>
                        <label>First name</label>
                        <div className='register-page-form-inner-wrapper-group-2-a' >
                            <span className='register-page-form-inner-wrapper-group-2-a-icon-1'><Person fontSize='1rem'/> </span>
                            <input 
                            disabled={interfaceState.button_disabled}
                            defaultValue={credentials.first_name}
                            onChange={(e) => handleChange(e)}
                            name="first_name"
                            type='text' placeholder='' />
                            {provideInputFeedback("first_name")}

                        </div>
                        </div>
                        <div>
                          <label>Last name</label>
                          <div className='register-page-form-inner-wrapper-group-2-a' >
                              <span className='register-page-form-inner-wrapper-group-2-a-icon-1'><Person fontSize='1rem'/> </span>
                              <input 
                              disabled={interfaceState.button_disabled}
                              defaultValue={credentials.last_name}
                              onChange={(e) => handleChange(e)}
                              name="last_name"
                              type='text' placeholder='' />
                              {provideInputFeedback("last_name")}

                          </div>
                        </div>
                        <div>
                          <label>Enrollment Key</label>
                          <div className='register-page-form-inner-wrapper-group-2-a' >
                              <span className='register-page-form-inner-wrapper-group-2-a-icon-1'><KeyOutlined fontSize='1rem'/> </span>
                              <input 
                              disabled={interfaceState.button_disabled}
                              defaultValue={credentials.enrollmentKey}
                              onChange={(e) => handleChange(e)}
                              name="enrollmentKey"
                              type='text' placeholder='' />
                              {provideInputFeedback("enrollmentKey")}

                          </div>
                        </div>
                        <div 
                        className='register-page-form-inner-wrapper-group-2-c'>
                            <Link to={CLIENT_LINKS.forgot_password_page.url} className='router-dom-LINK'>
                                <strong>Forgot Password?</strong>
                            </Link>
                        </div>

                    </div>
                    {
                    // interfaceState.isSpinnerActive &&
                    <div style={{height: '0.5rem'}}>
                    <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
                    </div>
                    }
                    <div className='register-page-form-inner-wrapper-group-3'>
                        
                        <button 
                        type='submit'
                        disabled={interfaceState.button_disabled}
                        // onClick={(e)=>handleSubmit(e)}
                        onClick={(e)=>handleRegister(e)}
                        className='register-page-form-inner-wrapper-group-3-a'>Create Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}




export default RegisterPage