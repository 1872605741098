import './forgotPasswordPage.scss'


import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute'
import { useNavToPrev } from '../../../hooks/useNavToPrev'

import { CheckCircle, CheckCircleOutline, InfoOutlined, Lock, Mail, MailOutline, SpaRounded} from '@mui/icons-material';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {useAuth} from '../../../contexts/AuthContext';
import axios from 'axios'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { UseHideTopbar } from '../../../hooks/UseTopbarVisibility';
import { API_LINKS, CLIENT_LINKS } from '../../../utils/LINKS';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { LinearProgress } from '@mui/material';
import { validateEmail_Util } from '../../../utils/Utils';
import { useWindowSize } from '../../../hooks/useWindowSize/useWindowSize';



const ForgotPasswordPage = () => {
  //if your cookies tally
  //display 2 boxes for new and new password confirm
  //redirect the user on setting new passwords
  //if any error with the cookies, set error and redirect to login page
  //so they can click the forgot pwd button

  UseHideSidebar()
  UseHideTopbar()
  const navigate = useNavigate()


  const {user, loading, error, dispatch,} = useAuth()
// console.log(user, 'printing out')
  const {width: userWindowWidth} = useWindowSize()
  const [credentials, setCredentials] = useState({
    email: "",
    lastEmailStore: "",
    password: "",
    isResetLinkEnabled: true,
    sentInitially: false, //so we render the resend initial link
    userWindowWidth: userWindowWidth
    // first_name: '',
    // last_name: ''
  })

  // !!!!!!!!!!!!!! resetLink, enableResetLink, disableResetLink should all be 
  // !!!!! actuall resendLink, enableResendLink, disableResendLink
  //But i dont want to fix it yet. Why not fix it now?

  const handleChange = (e) => {
    setCredentials((prev) => ({...prev, [e.target.name]: e.target.value}))
  }
  const enableResetLink = () => {
    setCredentials((prev) => ( {...prev, isResetLinkEnabled: true } ))
  }
  const disableResetLink = () => {
    setCredentials((prev) => ( {...prev, isResetLinkEnabled: false} ))
  }




  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false

    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }




  const goBack = () => {
    navigate(-1)
  }

  const initialCountDown = 99
  const [timer, setTimers] = useState({
    countDown: 99
  })

  const resetCountdownTimer = () => {
    setTimers(prev => ({...prev, countDown: initialCountDown}))
  }

  useEffect (() => {
  
    //this effect is running on mount. How do we stop the rubbish
    //because running on mount makes the timer set on and it keeps counting and stop til it reaches zero because our initial state will always be at > 1 or high value
    //we can solve this by setting up a guard return block 
    //or we set initallized as 0 . And set it to 10 just before we start anything we wanna do
    let timeoutResend
    const countDown = () => {


      if(timer.countDown < 1){
        //turn it back on
        enableResetLink()
        return ''
      }
      //ensure the reset link is disabled
      timeoutResend = setTimeout(() => {
        setTimers(prev => ({...prev, countDown : prev.countDown - 1}))
      }, 1000);

    }
    countDown()


    return () => {
      clearTimeout(timeoutResend)
    }
  }, [timer.countDown])

  useEffect (() => {
    const runTheResetCountdownTimer = () => {
      // if( timer.countDown < 10 ){
        resetCountdownTimer()
      // }
    }
    runTheResetCountdownTimer()
    //if the reset link is enabled and then reset the countdown timer

  }, [credentials.isResetLinkEnabled])

  const handleResendLink = async() => {
    try{
      hideSnackbar()
      disableButton()
      //await 
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      showSpinner()
      const res = await axiosInstance.post('/auth/forgot-password', credentials)

      //after awaiting
      disableResetLink()
      setCredentials((prev) => ( {...prev, lastEmailStore: credentials.email } ))

      setSnackbarMessage(  `${
        res?.data?.message || 
        (  `Password reset link sent to ${credentials.email || 'your email'} Check your mail inbox now` )
      } `)
      setSnackbarSeverity("success")
      setSnackbarDuration(1*60*60*24) //24 hrs
      showSnackbar()

    }
    catch(error){
      setSnackbarMessage(`Failed to resend link. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      showSnackbar()

    }
    finally{
      enableButton()
      hideSpinner()
    }

  }
  

  const handleResetPassword = async (e) => {
    // console.log('hi')
    e.preventDefault()

    try{
      hideSnackbar()
      disableButton()
      
      if(true){
        if(!credentials.email  ){
            setSnackbarMessage('Please fill the email field to proceed' )
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
        }

        const {email} = credentials
        const {isValid: isEmailValid} = validateEmail_Util(email)

        if(!isEmailValid){
          alertSnackbar('Email is not valid click the info button in the input box', 'error')
          return ''
        }
      }

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      // const res = await axiosInstance.post(API_LINKS.login.url, credentials)
      showSpinner()
      const res = await axiosInstance.post('/auth/forgot-password', credentials)

      setCredentials((prev) => ( {...prev, lastEmailStore: credentials.email } ))
      setCredentials((prev) => ( {...prev, sentInitially: true} ))
      disableResetLink()
      // setSnackbarMessage(`Password reset link sent to ${credentials.email || 'your email'} Check your mail inbox now` )
      setSnackbarMessage(  `${
        res?.data?.message || 
        (  `Password reset link sent to ${credentials.email || 'your email'} Check your mail inbox now` )
      } `)

      setSnackbarSeverity("success")
      setSnackbarDuration(1*60*60*24) //24 hrs
      showSnackbar()
      // return navigate(CLIENT_LINKS.home.url)
    }
    catch(error){

      console.error(error)
      setSnackbarMessage(`Failed to reset password. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()
    }
    finally{
      enableButton()
      hideSpinner()
    }
  }
  console.log(credentials)

  useEffect(() => {

    const hideCreateNewRequestButton = () => {
      if(!credentials.sentInitially){
        // return ''
      }
      if( (credentials.lastEmailStore === credentials.email) && (credentials.email !== '')  ){
        setCredentials((prev) => ( {...prev, sentInitially: true} ))
        return ''
      }

      setCredentials((prev) => ( {...prev, sentInitially: false} ))
    }


    hideCreateNewRequestButton()
    
    
    
  
    // return () => {
    //   second
    // }
  }, [credentials.email])
  

  const provideInputInfo = (name) => {
    // const {name, value} = e.target

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }

  }

  const provideInputFeedback = (name) => {
    
    const value = credentials[name]
    //i.e credentials.name that is the value of the password, name etc
    

    if(name === "email"){
      const {isValid} = validateEmail_Util(value)
      // console.log('hey')
      // console.log(isValid)
      if(isValid){
        return (
        <span 
        style={{color: 'green', display:'flex', alignItems:'center', fontSize: '1.1rem'}}
        className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> </span>)
      }
      
      return <span 
      style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
      onClick={()=> provideInputInfo(name) }
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1.2rem'/> </span>
      
    }


    // //default
    <span
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'>
    <InfoOutlined fontSize='1rem'/> </span>

    
  }


  return (
    <div className='login-page'>
        <ReusableSnackbarComponent 
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
        />
        {/* <img className='login-page-bg-image' src={login_bg} alt=''/> */}
        <div className='login-page-form-container'>
            <div className='login-page-form'>
                <div className='login-page-form-inner-wrapper'>
                    <div className='login-page-form-inner-wrapper-group-1'>
                        <h1 style={{
                              fontWeight: 300,
                              // fontSize: 
                              // color: 'red',
                              // fontSize: '0.5rem'
                            }}className='login-page-form-inner-wrapper-group-1-a' >Forgot password</h1>
                        <div className='login-page-form-inner-wrapper-group-1-b' >
                            <strong onClick={goBack} style={{
                              fontWeight: 200
                            }}> log in</strong>
                        </div>
                    </div>
                    <div className='login-page-form-inner-wrapper-group-2'>
                        <div>
                        <label>Email</label>
                        <div className='login-page-form-inner-wrapper-group-2-a' >
                            <span className='login-page-form-inner-wrapper-group-2-a-icon-1'><Mail fontSize='1rem'/> </span>
                            <input 
                            // disabled={}
                            onChange={(e) => handleChange(e)}
                            name="email"
                            type='email' 
                            placeholder='...enter your email account' />
                            {provideInputFeedback("email")}
                        </div>
                        </div>
                        {
                        !credentials?.isResetLinkEnabled &&
                        <div 
                        className='login-page-form-inner-wrapper-group-2-c'>
                            <span style={{
                              fontWeight: 300
                            }}>Send again :</span>
                            <span style={{
                              fontWeight: 300
                            }}>{timer.countDown}</span>
                        </div>
                        }
                        {
                        credentials?.isResetLinkEnabled && credentials?.sentInitially &&
                        <div 
                        className='login-page-form-inner-wrapper-group-2-c'>
                            <span 
                            onClick={handleResendLink}
                            disabled={interfaceState.button_disabled}
                            style={{
                              fontWeight: 300
                            }}>Resend reset link</span>
                        </div>
                        }
                    </div>
                    {
                    // interfaceState.isSpinnerActive &&
                    <div style={{height: '0.5rem'}}>
                    <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
                    </div>
                    }
                    {
                    credentials.isResetLinkEnabled && !credentials?.sentInitially &&
                    <div className='login-page-form-inner-wrapper-group-3'>
                        <button 
                        disabled={interfaceState.button_disabled }
                        type='submit'
                        // onClick={(e)=>handleSubmit(e)}
                        onClick={(e)=>handleResetPassword(e)}
                        className='login-page-form-inner-wrapper-group-3-a'>Reset Password</button>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}



export default ForgotPasswordPage




   