import './loginPage.scss'


import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute'
import { useNavToPrev } from '../../../hooks/useNavToPrev'

import { CheckCircle, CheckCircleOutline, InfoOutlined, Lock, Mail, MailOutline, VisibilityOutlined} from '@mui/icons-material';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useContext, useState } from 'react';
import {useAuth} from '../../../contexts/AuthContext';
import axios from 'axios'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { UseHideTopbar } from '../../../hooks/UseTopbarVisibility';
import { API_LINKS, CLIENT_LINKS } from '../../../utils/LINKS';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { LinearProgress } from '@mui/material';
import { validateEmail_Util, validatePassword_Util } from '../../../utils/Utils';



const LoginPage = () => {
  // console.log('HELLO PEOPLE')
  UseHideSidebar()
  UseHideTopbar()
  const navigate = useNavigate()
  const location = useLocation()
  const { navAndAddPrevLocation, navAndUsePrevLocation} = useNavConsiderPrevRoute()
  

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false

    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }


  const {user, loading, error, dispatch,} = useAuth()
// console.log(user, 'printing out')
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    passwordType: 'password',
    // first_name: '',
    // last_name: ''
  })

  const handleChange = (e) => {
    setCredentials((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const togglePasswordVisibility = async() => {

    if(credentials.passwordType === 'password') (
      setCredentials((prev) => ({...prev, passwordType: 'text' }))
      
      )
    if(credentials.passwordType === 'text') {
      setCredentials((prev) => ({...prev, passwordType: 'password' }))
      
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 9000); // 10000 milliseconds = 10 seconds
  });

  setCredentials((prev) => ({...prev, passwordType: 'password' }))


  
  //if it is text do nothing. This prevents us from having to disable button
  }

  const provideInputInfo = (name) => {
    // const {name, value} = e.target

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "password"){
      const requirements = 
      <>
      <div>Password must be at least 8 characters in length </div>
      <div>Password must contain at least one digit </div>
      <div>Password must contain at least one lowercase letter</div>
      <div>Password must contain at least one uppercase letter. </div>
      <div>Password must contain at least one special character </div>
      </>

      alertSnackbar(requirements)


      return ''
    }

    
  }



  const provideInputFeedback = (name) => {
    //this function runs on every state change
    //we should rather use an effect for this in the case there are many other things that are changing

    //this should be in a use effect that changes when creedntials changes
    
    // console.log(e)
    // const {name, value} = e?.target
    // const {name,value} = e?.target
    // console.log(name, value)
    // return ''
    const value = credentials[name]
    //i.e credentials.name that is the value of the password, name etc
    

    if(name === "email"){
      const {isValid} = validateEmail_Util(value)
      // console.log('hey')
      // console.log(isValid)
      if(isValid){
        return (
        <span 
        style={{color: 'green', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
        className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> </span>)
      }
      
      return <span 
      style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
      onClick={()=> provideInputInfo(name) }
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>
      
    }
    if(name === "password"){
      const {isValid }= validatePassword_Util(value)
      if(isValid){
        return (
        <span 
        style={{ color: 'green', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
        className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> </span>)
      }
      
      return <span 
      // style={{color: 'green'}}
      style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
      onClick={()=> provideInputInfo(name) }
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>
    }



    // //default
    <span
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'>
    <InfoOutlined fontSize='1rem'/> </span>

    
  }



  const handleLogin = async (e) => {
    // console.log('hi')
    e.preventDefault()

    dispatch({type: 'LOGIN_START'})
    try{
      hideSnackbar()
      disableButton()
      if(true){
        if(!credentials.password  ){
            setSnackbarMessage('Please fill the password field to proceed' )
            setSnackbarSeverity( "error")
            showSnackbar()
            return ''
            // return alert('Please fill all fields to proceed')
        }
        if(!credentials.email  ){
          setSnackbarMessage('Please fill the email field to proceed' )
          setSnackbarSeverity( "error")
          showSnackbar()
          return ''
          // return alert('Please fill all fields to proceed')
      }
      }
      if(true){
        const {email, password} = credentials
        const {isValid: isEmailValid} = validateEmail_Util(email)
        const {isValid: isPasswordValid} = validatePassword_Util(password)

        if(!isEmailValid){
          alertSnackbar('Email is not valid click the info button in the input box', 'error')
          return ''
        }
        if(!isPasswordValid){
          alertSnackbar('Password is not valid click the info button in the input box', 'error')
          return ''
        }

      }


      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      showSpinner()
      const res = await axiosInstance.post('/auth/login', credentials)
      // console.log(typeof(res.data.status.toString()[0]))
      // if(res?.data?.status?.toString()[0] !== "2"){
      //   //meaning if it does not begin with a 2
      //   //2xx are success codes
      //   throw new Error(`${res?.data?.message || "Failed to login fe"}`)
      // }
      dispatch({
          // type: 'LOGIN_SUCCESS', payload: res.data
          type: 'LOGIN_SUCCESS', 
          payload: res?.data?.message || ''
      })
      alertSnackbar('Login successful', 'success')

      // return navigate(CLIENT_LINKS.home.url)
      // await new Promise((resolve) => {
        // setTimeout(() => {
          // resolve();
        // }, 5000); // 10000 milliseconds = 10 seconds
      // });
      
      navAndUsePrevLocation(CLIENT_LINKS.home.url)

      if(location?.pathname?.toString() === '/login'){
        navAndUsePrevLocation(CLIENT_LINKS.home.url)
      }
    
    }
    catch(error){
      // alert(err.toString())
      // let temp = 'an error occurred'
      // console.log(err)
      // console.log(err?.response?.data?.message)
      // console.log('failure occurred')
      // if(err?.response?.data?.custom_code === 4 || err?.response?.data?.custom_code === 5 ){
      //   console.log('no user or invalid credentials')
      //   temp = 'no user or invalid credentials'
      // }
      // if(err?.response?.data?.message){
      //   temp = err.response.data.message
      // }
      // alert(`${err?.response?.data?.message?.toString()}`)

      let temp = 'an error occurred'


      setSnackbarMessage(`${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()


      console.error(error)
      dispatch({
          type:'LOGIN_FAILURE', 
          payload: temp
      })

      enableButton()
    }
    finally{
      enableButton()
      hideSpinner()
    }
  }



  

  return (
    <div className='login-page'>
        <ReusableSnackbarComponent 
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
        />
        {/* <img className='login-page-bg-image' src={login_bg} alt=''/> */}
        <div className='login-page-form-container'>
            <div className='login-page-form'>
                <div className='login-page-form-inner-wrapper'>
                    <div className='login-page-form-inner-wrapper-group-1'>
                        <h1 className='login-page-form-inner-wrapper-group-1-a' >Sign in</h1>
                        <div className='login-page-form-inner-wrapper-group-1-b' >New User?  
                            <Link to='/register' className='router-dom-LINK'>
                                <strong> Create an Account</strong>
                            </Link>
                        </div>
                    </div>
                    <div className='login-page-form-inner-wrapper-group-2'>
                        <div>
                        <label>Email</label>
                        <div className='login-page-form-inner-wrapper-group-2-a' >
                            <span className='login-page-form-inner-wrapper-group-2-a-icon-1'><Mail fontSize='1rem'/> </span>
                            <input 
                            onChange={(e) => handleChange(e)}
                            name="email"
                            type='email' placeholder='Email' />
                            {provideInputFeedback("email")}
                        </div>
                        </div>
                        <div>
                        <label>Password</label>
                        <div className='login-page-form-inner-wrapper-group-2-b' >
                            <span className='login-page-form-inner-wrapper-group-2-b-icon-1'><Lock fontSize='1rem'/> </span>
                            <input 
                            onChange={(e) => handleChange(e)}
                            name="password"
                            type={credentials?.passwordType || 'password'}
                            placeholder='Password' />
                           {
                            credentials?.password &&
                            <span 
                             onClick={togglePasswordVisibility}
                             style={{cursor: 'pointer',display:'flex', alignItems:'center', fontSize: '1.1rem', paddingRight:'0.3rem', color: 'gray' }}
                              className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                            </span>
                            }
                            {provideInputFeedback("password")}
                        </div>
                        </div>
                        <div 
                        className='login-page-form-inner-wrapper-group-2-c'>
                            <Link to={CLIENT_LINKS.forgot_password_page.url} className='router-dom-LINK'>
                                <strong>Forgot Password?</strong>
                            </Link>
                        </div>
                        {/* <div style={{color: 'red'}}>
                          {error && error}
                        </div> */}
                    </div>
                    {
                    // interfaceState.isSpinnerActive &&
                    <div style={{height: '0.5rem'}}>
                    <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
                    </div>
                    }
                    <div className='login-page-form-inner-wrapper-group-3'>
                        <button 
                        disabled={interfaceState.button_disabled}
                        type='submit'
                        // onClick={(e)=>handleSubmit(e)}
                        onClick={(e)=>handleLogin(e)}
                        className='login-page-form-inner-wrapper-group-3-a'>Sign In</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}



export default LoginPage




   