import "./app.scss";
import { BrowserRouter, Routes, Route, useLocation, Navigate, useNavigate} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

// import { AuthProvider, useAuth } from "./context/AuthContext";
import { useAuth } from "./contexts/AuthContext";
import { AuthProvider } from "./contexts/AuthContext";
import { ThesesProvider } from "./contexts/ThesesContext";
import { OtherContextProvider, useOtherContext } from "./contexts/OtherContext";


import {ThemeContextProvider} from './contexts/ThemeContext'
import UseScrollToTop from "./hooks/UseScrollToTop";
import { ADMIN_LINKS, CLIENT_LINKS, ROUTER_LINKS } from "./utils/LINKS";
import Sidebar from "./components/sidebar/Sidebar";
import LoginPage from './pages/auth/LoginPage/LoginPage'
import LogoutPage from './pages/auth/LogoutPage/LogoutPage'
import RegisterPage from './pages/auth/RegisterPage/RegisterPage'
// import VerifyAccountPage from './pages/auth/VerifyAccountPage/VerifyAccountPage.jsx'
import VerifyAccountSuccessPage from './pages/auth/VerifyAccountSuccessPage/VerifyAccountSuccessPage.jsx'
import ResetPasswordSuccessPage from './pages/auth/ResetPasswordSuccessPage/ResetPasswordSuccessPage.jsx'// 
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage/ForgotPasswordPage.jsx'

import HomePage from "./pages/home/HomePage"; 
import ViewAuthorPage from "./pages/author/ViewAuthorPage/ViewAuthorPage";
import CreateThesisPage, { CreateThesisProvider } from "./pages/author/CreateThesisPage/CreateThesisPage";

import UpdateThesisPage, { UpdateThesisProvider } from "./pages/author/UpdateThesisPage/UpdateThesisPage";
import { AdminContextProvider } from "./contexts/AdminContext";

import AdvancedSearchPage from "./pages/others/AdvancedSearchPage/AdvancedSearchPage";
import { UseHideSidebar, UseShowSidebar } from "./hooks/UseSidebarVisibility";
import Topbar from "./components/topbar/Topbar";
import { UseShowTopbar } from "./hooks/UseTopbarVisibility";
import OcrModulePage from "./pages/OcrModulePage/OcrModulePage";
// import SearchResultsPage from "./pages/searchResultsPage/SearchResultsPage";
// import {ReusableFilterResultsBox} from "./pages/searchResultsPage/SearchResultsPage";
import ViewThesisPage from "./pages/viewer/ViewThesisPage/ViewThesisPage";
import Test from "./pages/test/Test";
import PreviewDocument from "./pages/previewDocument/PreviewDocument";
import ViewAllThesesPage from "./pages/viewer/ViewAllThesesPage/ViewAllThesesPage";
// import AdminPanelPage from "./pages/admin/AdminPanelPage/AdminPanelPage";
import AdvancedSearchResultsPage from "./pages/advancedSearchResultsPage/AdvancedSearchResultsPage";
import UseReloadOnURLChange from "./hooks/UseReloadOnURLChange";

import AuthorManageThesesPage from "./pages/author/ManageThesesPages/AuthorManageThesesPage.jsx"
import {AuthorManageSpecificThesis} from "./pages/author/ManageThesesPages/AuthorManageSpecificThesis.jsx";
import {AdminManageSpecificThesis} from "./pages/author/ManageThesesPages/AdminManageSpecificThesis.jsx";
import NotificationsPage from './pages/others/NotificationsPage/NotificationsPage.jsx'
import AccountSettingsPage from './pages/others/AccountSettingsPage/AccountSettingsPage.jsx'


import { useEffect, useLayoutEffect, useState } from "react";
import {ReusableDraggableDialogForAppJS} from "./components/reusables/ReusableDraggableDialog/ReusableDragableDialog";
import SavedThesesLibraryPage from "./pages/others/SavedThesesLibraryPage/SavedThesesLibraryPage";
import AdminHomePage from "./pages/admin/admin-pages/AdminHomePage/AdminHomePage";
import AdminManageAccounts from "./pages/admin/admin-pages/AdminManageAccounts/AdminManageAccounts";
import AdminManageCategories from "./pages/admin/admin-pages/AdminManageCategories/AdminManageCategories";
import AdminManageResearchType from "./pages/admin/admin-pages/AdminManageResearchType/AdminManageResearchType";
import AdminManageSupervisors from "./pages/admin/admin-pages/AdminManageSupervisors/AdminManageSupervisors";
import AdminManageTheses from "./pages/admin/admin-pages/AdminManageTheses/AdminManageTheses";
import AdminManageAdministrators from "./pages/admin/admin-pages/AdminManageAdministrators/AdminManageAdministrators";
import AdminManageMessages from "./pages/admin/admin-pages/AdminManageMessages/AdminManageMessages";
import AccountMessagesPage from "./pages/others/AccountMessagesPage/AccountMessagesPage";
import AdminManageFullTextRequests from "./pages/admin/admin-pages/AdminManageFullTextRequests/AdminManageFullTextRequests";
import AboutSupervisorPage from "./pages/supervisor/AboutSupervisorPage/AboutSupervisorPage"
import useMobileRedirect from "./hooks/useMobileRedirect/useMobileRedirect";
import useMonitorUserAuthentication from "./hooks/useMonitorUserAuthentication/useMonitorUserAuthentication.jsx";
import { useNavConsiderPrevRoute } from "./hooks/useNavConsiderPrevRoute.jsx";
import ReusableSnackbarComponent from "./components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent.jsx";
import AdminManageEnrollmentKeys from "./pages/admin/admin-pages/AdminManageEnrollmentKeys/AdminManageEnrollmentKeys.jsx";
import { useClearExpLocalStorageItems } from "./hooks/useClearExpLocalStorageItems/useClearExpLocalStorageItems.jsx";


// Sidebar should have 

// Profile icon that opens modal:
// 	My thesis (page)
// 	Edit account (page)

// Homepage icon
// Increase and decrease size

// Scroll to top and bottom icon -absolute at right bottom

const BaseUrlPage = () => {

  const {user, dispatch, authMonitorStates} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    isAuthenticated,
    snackbarVisible: authSnackbarVisible, 
    snackbarMessage: authSnackbarMessage, 
    snackbarSeverity : authSnackbarSeverity, 
    snackbarDuration: authSnackbarDuration, 
    
  } = authMonitorStates

  // useLayoutEffect(() => {

    // const checkThings = () => {
    if(!isAuthenticated ){
      // return navigate(CLIENT_LINKS.login.url)
      return <Navigate to={CLIENT_LINKS.login.url} />
    }
    if(!user?._id){
      // return navigate(CLIENT_LINKS.login.url)
      return <Navigate to={CLIENT_LINKS.login.url} />

    }

    // navigate(CLIENT_LINKS.home.url)
    return <Navigate to={CLIENT_LINKS.home.url} />

    // }
    // checkThings()

  // }, [location.pathname, user?._id, isAuthenticated])


  // return (
  //   <div>

  //   </div>
  // )
}



const UnwantedRoute = ({children}) => {
  const { user } = useAuth()
  if(user){
    return children
    // return <Navigate to='/signup' />

  }
  else{
    // return <Navigate to='/signup' />
    return 
  }
}

const LoggedInRoute = ({children}) => {
  const {user} = useAuth()
  if(user){
    //means the user is logged in already
    return <HomePage />
  }
  else{
    return children
  }
}

const CantReloginRoute = ({children}) => {
  const {user} = useAuth()
  // const navigate = useNavigate()
  // const {navAndUsePrevLocation} = useNavConsiderPrevRoute()
  if(user){
    //means the user is logged in already
    // return <HomePage />
    return <Navigate to={CLIENT_LINKS.home.url} />
    // return navAndUsePrevLocation('')

  }
  else{
    return children
  }
}

function AppMain() {
  // console.log(window.location)
  
  useMobileRedirect()
  useMonitorUserAuthentication()
  useClearExpLocalStorageItems()
  

  const {user, dispatch, authMonitorStates} = useAuth()

  // console.log(authMonitorStates)
  // console.log(user)

  const {
    isAuthenticated,
    snackbarVisible: authSnackbarVisible, 
    snackbarMessage: authSnackbarMessage, 
    snackbarSeverity : authSnackbarSeverity, 
    snackbarDuration: authSnackbarDuration, 
    
  } = authMonitorStates

  // console.log('isAuth:', isAuthenticated)
  // console.log('snackbarVisible', authSnackbarVisible)
  // console.log(authSnackbarMessage)

  
  const toggleAuthSnackbar = () => {
    dispatch({
      type: "TOGGLE_AUTH_MONITOR_SESSION_SNACKBAR"
    })
    // externalModifyAuthenticationState({snackbarVisible: false})
  }


  

  const {sidebar_enabled, topbar_enabled, sidebar_width, topbar_height} = useOtherContext()
  // console.log(sidebar_enabled, sidebar_size)


  const renderSidebarAndBodyWidth = () => {
    if(!sidebar_enabled){
      //if sidebar is disabled
      return {
        sidebar_display: 'none',
        sidebar_width: 0,
        // sidebar_margin_top: topbar_height,

        main_content_width: 100,
        // main_content_margin_top: topbar_height,
        main_content_margin_left: 0

      }
    }

    return {
      sidebar_display: 'block',
      sidebar_width: sidebar_width,
      // sidebar_margin_top: topbar_height,

      main_content_width: 100 - sidebar_width,
      // main_content_margin_top: topbar_height,
      main_content_margin_left: sidebar_width

    }
  }

  const renderSidebarAndBodyHeight = () => {
    if(!sidebar_enabled){
      //if sidebar is disabled
      return {
        sidebar_height: 0
      }
    }

    return {
      sidebar_height: `calc(100vh - ${topbar_height.toString()}rem)`
      // sidebar_height: `calc(100vh)`
      // sidebar_height: `calc(100vh)`
    }
  }

  const renderTopbarHeight = () => {
    if(!topbar_enabled){
      //if sidebar is disabled
      return {
        topbar_display: 'none',
        topbar_height: 0,
      sidebar_margin_top: 0,
      main_content_margin_top: 0,

      }
    }

    return {
      topbar_display: 'block',
      topbar_height: topbar_height,
      sidebar_margin_top: topbar_height,
      main_content_margin_top: topbar_height,
    }
  }

  // console.log(`calc(100vh - ${topbar_height}rem)`)


  const strCustom = (val, behind="rem") => {
    if(!val || !behind){
      // console.log('please supply a val or behind')
    }
    //adds '%' or 'rem' to a passed value
    var new_val = `${val}${behind}`
    return new_val
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation()
  return (
    <div className="root-app-div">


      {
        windowWidth < 240 &&
        <ReusableDraggableDialogForAppJS
        header={"Sorry"}
        main_body={
          <div>
            <div>
            "Please access this app on a laptop or larger screen for the best experience.
            </div>
            <div>"You may also try switching to landscape mode.</div>
          </div>
        }
        />
      }
      {
        <ReusableSnackbarComponent
        open={authSnackbarVisible}
        setOpen={() => toggleAuthSnackbar()}
        inner_message={authSnackbarMessage}
        severity={authSnackbarSeverity}
        autoHideDuration={authSnackbarDuration}
        />
      }



      {/* <UseHideSidebar /> */}
      <UseShowTopbar />
      {/* <UseShowSidebar /> */}
        <div
        className="app-cont">
          {/* <UseReloadOnURLChange /> */}

          <div
          style={{
            display: renderTopbarHeight().topbar_display,
            height: strCustom(renderTopbarHeight().topbar_height, 'rem'),
            position: 'fixed',
            top: 0
          }} 
          className="topbar-in-appjs"
          >
            <Topbar />
          </div>

          <div
          style={{
          }}
          className="appjs-body-below-topbar">
            {/* activate below ASAP */}
            {/* <UseScrollToTop /> */}
            {
            renderSidebarAndBodyWidth().sidebar_display &&
            <div 
            className="appjs-fixed-sidebar"
            style={{
              display: renderSidebarAndBodyWidth().sidebar_display,
              width: strCustom(renderSidebarAndBodyWidth().sidebar_width, '%'),
              marginTop: strCustom(renderTopbarHeight().topbar_height, 'rem'),
              position: 'fixed',
              // height: '100%',
              height: renderSidebarAndBodyHeight().sidebar_height,
              // backgroundColor: 'red',
              left: 0,
              // overflow: 'scroll'
              // display
              // width
              // marginTop
            }}>
              <Sidebar />
              {/* <ReusableFilterResultsBox /> */}
            </div>
            }
            <div className="appjs-main-content"
            style={{
              width: strCustom(renderSidebarAndBodyWidth().main_content_width, '%'),
              marginTop: strCustom(renderTopbarHeight().main_content_margin_top, 'rem'),
              marginLeft: strCustom(renderSidebarAndBodyWidth().main_content_margin_left, '%')
              // width
              // marginTop
              // marginLeft
              
            }}>
              <Routes  key={location.pathname}>
                <Route path='/'>
                  <Route path="/" element={
                    // <CantReloginRoute>
                      <BaseUrlPage />
                    // </CantReloginRoute>
                  }   />
                  {/* <Route path="/test" element={<Test />}   /> */}
                  {/* <Route path={`${CLIENT_LINKS.open_preview_of_server_document.url}/:id`} element={<PreviewDocument />}   /> */}
                  {/* <Route path={'open_preview_of_server_document:id'} element={<PreviewDocument />}   /> */}
                  {/* <Route path="/document_preview/:document_id" element={<PreviewDocument />}   />  */}
                  <Route path={CLIENT_LINKS.document_preview.url} element={<PreviewDocument />}   /> 
 
 
                  <Route index path="login" element={                     
                      <LoginPage />
                    } />
                  <Route index path="logout" element={ <LogoutPage />} />
                  <Route path="register" element={<RegisterPage />}   />
                  
                  {/* verify account page */}
                  {/* <Route path={`${CLIENT_LINKS.verify_account_page.url}`} element={<VerifyAccountPage />} /> */}

                  {/* verify  account success page */}
                  <Route path={`${CLIENT_LINKS.verify_account_success_page.url}/:verificationToken`} element={<VerifyAccountSuccessPage />} />
                  
                  {/*forgot password page */}
                  <Route path={`${CLIENT_LINKS.forgot_password_page.url}`} element={<ForgotPasswordPage />} />

                  {/* account settings page */}
                  <Route path={`${CLIENT_LINKS.reset_password_success_page.url}/:resetToken`} element={<ResetPasswordSuccessPage />} />

                  <Route path={CLIENT_LINKS.advanced_search.url} element={<AdvancedSearchPage />}   />
                  <Route path="/create_thesis" element={<CreateThesisPage />}   />


                  <Route path="/home" element={<HomePage />}   />
                  <Route path="/search" element={<HomePage />}   />
                  {/* <Route path={CLIENT_LINKS.search_results.url} element={<SearchResultsPage/>}   /> */}
                  <Route path={CLIENT_LINKS.advanced_search_results.url} element={<AdvancedSearchResultsPage/>}   />
                  {/* get messages for user account */}
                  <Route path={CLIENT_LINKS.get_account_messages.url} element={<AccountMessagesPage/>}   />

                  {/* view single thesis */}
                  <Route path={`${CLIENT_LINKS.view_thesis.url}/:thesis_id`} element={<ViewThesisPage /> }   />
                  {/* view all theses */}
                  <Route path="view_all_theses" element={<ViewAllThesesPage /> }   />


                  {/* author manage theeses*/}
                  <Route path={`${CLIENT_LINKS.manage_theses.url}`} element={<AuthorManageThesesPage/> }   />

                  {/* author manage all theses , for the auth user same as above*/}
                  <Route path={`${CLIENT_LINKS.author_manage_theses.url}`} element={<AuthorManageThesesPage/> }  />

                  {/* author manage specific thesis , for the auth user*/}
                  <Route path={`${CLIENT_LINKS.author_manage_specific_thesis.url}/:thesis_id`} element={<AuthorManageSpecificThesis/> }   /> 


                  {/* admin manage thesis any thesis i click for admin or any auth person so they dont have to go to admin section*/}
                  <Route path={`${CLIENT_LINKS.admin_manage_specific_thesis.url}/:thesis_id`} element={<AdminManageSpecificThesis /> }   />


                  {/* update thesis page */}
                  <Route path={`${CLIENT_LINKS.update_thesis.url}/:thesis_id`} element={<UpdateThesisPage /> }   />

                  {/* ocr module page */}
                  <Route path={CLIENT_LINKS.ocr_module.url} element={<OcrModulePage /> }   />
                  {/* saved theses library page */}
                  <Route path={CLIENT_LINKS.saved_theses_library.url} element={<SavedThesesLibraryPage/>} />

                  {/* about supervisor page */}
                  <Route path={`${CLIENT_LINKS.about_supervisor_page.url}/:supervisor_id`} element={<AboutSupervisorPage/>} />

                  {/* notifications page */}
                  <Route path={`${CLIENT_LINKS.notifications_page.url}`} element={<NotificationsPage />} />

                  {/* account settings page */}
                  <Route path={`${CLIENT_LINKS.account_settings_page.url}`} element={<AccountSettingsPage />} />

                  


                  {/* admin panel paths ie /admin_panel */}
                  <Route path={CLIENT_LINKS.admin_panel.url} >
                    <Route index element={<AdminHomePage />}  />
                    <Route path={ADMIN_LINKS.home.url} element={<AdminHomePage />}  />
                    <Route path={ADMIN_LINKS.admin_manage_accounts.url} element={<AdminManageAccounts />}  />
                    <Route path={ADMIN_LINKS.admin_manage_categories.url} element={<AdminManageCategories/>}  />
                    <Route path={ADMIN_LINKS.admin_manage_research_type.url} element={<AdminManageResearchType />}  />
                    <Route path={ADMIN_LINKS.admin_manage_supervisors.url} element={<AdminManageSupervisors />}  />
                    <Route path={ADMIN_LINKS.admin_manage_theses.url} element={<AdminManageTheses />}  />
                    <Route path={ADMIN_LINKS.admin_manage_administrators.url} element={<AdminManageAdministrators />}  />
                    <Route path={ADMIN_LINKS.admin_manage_full_text_requests.url} element={<AdminManageFullTextRequests />}  />
                    <Route path={ADMIN_LINKS.adminManageEnrollmentKeys.url} element={<AdminManageEnrollmentKeys />}  />
                    <Route path={ADMIN_LINKS.admin_manage_messages.url} element={<AdminManageMessages />}  />
                  </Route>
                  
                </Route>  
              </Routes>
            </div>
          </div>

        </div>
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
   queries: {
    networkMode: 'always' //remove this in production
   }
  }
})

const App = () => {
  // const location = useLocation()

  return (
    <QueryClientProvider client={queryClient} >
      <AdminContextProvider>
        <UpdateThesisProvider>
          <CreateThesisProvider>
            <OtherContextProvider>
              {/* <ThesesProvider> */}
                {/* <ThemeContextProvider> */}
                  <AuthProvider>
                    <BrowserRouter>    
                        <AppMain />
                    </BrowserRouter>
                  </AuthProvider>
                {/* </ThemeContextProvider> */}
              {/* </ThesesProvider> */}
            </OtherContextProvider>
          </CreateThesisProvider>
        </UpdateThesisProvider>
      </AdminContextProvider>
    </QueryClientProvider>

  )
}


export default App;
