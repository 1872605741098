// import React from 'react'
import './adminManageSupervisors.scss'

// import React from 'react'
import { AddOutlined, Close, InfoOutlined, ManageAccountsOutlined, Search, Settings } from '@mui/icons-material'
import ReusablePaginationComponent from '../../../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent'
import ReusableHorizontalScrollMenu from '../../../../components/reusables/ReusableHorizontalScrollMenu/ReusableHorizontalScrollMenu'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { UseHideSidebar } from '../../../../hooks/UseSidebarVisibility'
import { ReusableLoader3, ReusableLoaderInCenter } from '../../../../components/reusables/ReusableLoaders/ReusableLoaders'
import ReusableSnackbarComponent from '../../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent'
import {useAdminContext} from '../../../../contexts/AdminContext'
import { useAuth } from '../../../../contexts/AuthContext'
import { ReusableDraggableDialog } from '../../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog'
import { useEffect } from 'react'
import { ReusablePageSettingsDialogAdmin } from '../../../../components/reusables/ReusablePageSettingsDialog/ReusablePageSettingsDialogAdmin'
import ReusableFullscreenDialog from '../../../../components/reusables/ReusableFullscreenDialog/ReusableFullscreenDialog'
import {authorized_privilege_levels_that_are_admin_Util, authorized_privilege_levels_that_create_supervisor_Util, authorized_privilege_levels_that_delete_users_Util, authorized_privilege_levels_that_update_users_Util, checkIfUserHasPrivilegeLevelFor_Util } from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom'
import { CLIENT_LINKS } from '../../../../utils/LINKS'

const FullScreenModalAddNewSupervisorBody = ({ open, setOpen}) => {
  const {dispatch} = useAdminContext()
  const {user} = useAuth()

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 5000,
    button_disabled : false,
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setInterfaceMessage = (name, message) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: message  }))
  }
  const setInterfaceSeverity = (name, severity) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }


  const handleClose = async () => {
    try{
      // if(otherFunctionsPassed){
      //   await otherFunctionsPassed()
      // }
      setOpen(false);
    }
    catch(error){
      return alert('an error occurred')
    }
  };

  const [componentState, setComponentState] = useState({
    supervisor_email: '',
    supervisor_name: '',
    supervisor_title: '',
    department: '',
    faculty: '',
    institution: '',
    institution_abbreviation: ''
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setComponentState((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const navigate = useNavigate()
  const [componentTimeouts, setComponentTimeouts] = useState({
    navigateLoginTimeout: ''
  })
  const handleComponentTimeouts = (key, value) => {
    setComponentTimeouts(prev => ({...prev, [key]: value }))
  }

  const handleCreate= async () => {
    try{

      const user_privilege_levels = user?.privilege_levels
    
      if(!user_privilege_levels || user_privilege_levels?.length < 1){
        //alert error
          setInterfaceMessage('snackbar_message', "Please login")
        setInterfaceSeverity('snackbar_severity', "error")
        showSnackbar()
        // toggleInterfaceVisibility('snackbar_visible')

        handleComponentTimeouts(
          'navigateLoginTimeout', 
          setTimeout(() => {
            navigate(CLIENT_LINKS.login.url)
          }, 1000) 
        )
        return ''
      }
      const can_create_supervisor = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_create_supervisor_Util, user_privilege_levels )
    
      if(can_create_supervisor){
        if(!renderedPromptToDelete){
          setInterfaceMessage('snackbar_message', "This action will create a new supervisor. Click again to confirm")
          // handleInterfaceState('snackbar_autohide_duration', '')
          setSnackbarDuration(5000)
          setInterfaceSeverity('snackbar_severity', "warning")
          // toggleInterfaceVisibility('snackbar_visible')
          showSnackbar()
          setRenderedPromptToDelete(true) //confirmation prompt
          
          return ''
        }
        setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))


        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })
  
        const body = {
          ...componentState,
        }
        const res = await axiosInstance.post(`/supervisor/create_supervisor`, body)
        // const res = await axiosInstance.post(`/users/admin_update_user/bac`, body)

        setInterfaceMessage('snackbar_message', "Successfully created new supervisor")
        setInterfaceSeverity('snackbar_severity', "success")
        // toggleInterfaceVisibility('snackbar_visible')
        showSnackbar()
        // console.log('SUCCESS')

        dispatch({
          type: "ADMIN_FORCE_UPDATE"
        })

        setTimeout(() => {
          setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

          handleClose()
          // console.log('This runs once after 2000ms');
        }, 500);
        
        return ''
      }



      throw new Error("You are not granted permission to perform this action ")

    }
    catch(error){
      //  return <ReusableSnackbarComponent open={true}  inner_message={'Updated successfully'} autoHideDuration={2000}/>
      setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

      setInterfaceMessage('snackbar_message', `Failed to create supervisor. Reason: ${error.response?.data?.message  || 'An error occurred' }`)
      setInterfaceSeverity('snackbar_severity', "error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()


      console.error(error)
      // return alert(error.response.data?.message || 'An error occurred')
    }
    finally{
      // toggleInterfaceVisibility('snackbar_visible')
      // toggleInterfaceVisibility('snackbar_visible', '')
      showSnackbar()

    }
  }

  const [renderedPromptToDelete, setRenderedPromptToDelete] = useState(false)
  //can be for anything that requires confirmation

  useEffect(() => {
    //if any of these change, user must reconfirm deletion
    if(renderedPromptToDelete){
      setRenderedPromptToDelete(false) //same as say allow deletion
      // disableButton()
    }
  }, [componentState ])

  useEffect(() => {
    let timer
    //after 3 seconds, if user fails to delete, they will need to reconfirm
    if(renderedPromptToDelete){

      timer = setTimeout(() => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
        setRenderedPromptToDelete(false)

        // console.log('This runs once after 2000ms');
      }, 5000);

    }

    return () => clearTimeout(timer)
  }, [renderedPromptToDelete])




  //refetch the id  
  return(
    <div className='amap-fullscreen-modal-body'>
      <ReusableSnackbarComponent 
      severity={interfaceState.snackbar_severity}
      open={interfaceState.snackbar_visible}  
      inner_message={interfaceState.snackbar_message} 
      setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
      autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>

      <div className='amap-fullscreen-modal-header'>
        <div className='amap-fullscreen-modal-header-inner'>
          <span className='close'
          onClick={handleClose}
          > <Close fontSize='2rem' /> </span>
          <span className='title'>Create New Supervisor</span>
          <span 
          onClick={handleCreate}
          className='update'>CREATE</span>
        </div>
      </div>
      <div className='amap-fullscreen-modal-body-inner'>
        <div className='amap-fullscreen-modal-body-form-section'>
          <div  className='create-thesis-page-form-item'>
            <label>Supervisor email</label>
            <input 

            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="supervisor_email" 
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Supervisor title (Prof, Mrs, Doctor, etc)</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="supervisor_title" 
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Supervisor name (required)</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="supervisor_name" 
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>About Supervisor</label>
            <textarea
            className="create-thesis-page-form-item-textarea"
            cols="40"
            onChange={(e) => handleChange(e)}
            type='text' 
            name="supervisor_about" 
            // defaultValue={passed_supervisor?.supervisor_about || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Affiliated department</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="department" 
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Affiliated institution</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="institution" 
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Affiliated institution abbreviation (e.g OAU, UNILORIN, FUOYE)</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="institution_abbreviation" 
            />
          </div>

        
        </div>

        <div></div>
      </div>
    </div>
  )
}


const FullScreenModalBody = ({passed_supervisor, open, setOpen}) => {
  // console.log(passed_supervisor)
  const {dispatch} = useAdminContext()
  const {user} = useAuth()

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setInterfaceMessage = (name, message) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: message  }))
  }
  const setInterfaceSeverity = (name, severity) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }



  

  const handleClose = async () => {
    try{
      // if(otherFunctionsPassed){
      //   await otherFunctionsPassed()
      // }
      setOpen(false);
    }
    catch(error){
      return alert('an error occurred')
    }
  };

  const [componentState, setComponentState] = useState({
    ...passed_supervisor,
    privilege_level_input : ''
  })
  console.log(componentState)

  const handleChange = (e) => {
    const {name, value} = e.target
    setComponentState((prev) => ({...prev, [e.target.name]: e.target.value}))
  }


  const fetchUser = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    // const response = await axiosInstance.get('/users/find_all');
    const response = await axiosInstance.get(`/supervisor/find_supervisor/${passed_supervisor._id}`);
    console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_user , isLoading: isLoadingUser, refetch:refetchUser } = useQuery({
    queryKey: ['specific_user'],
    // networkMode: 'always',
    queryFn: () =>
      fetchUser(),
    // enabled: false
  })



  const navigate = useNavigate()
  const [componentTimeouts, setComponentTimeouts] = useState({
    navigateLoginTimeout: ''
  })

  const handleComponentTimeouts = (key, value) => {
    setComponentTimeouts(prev => ({...prev, [key]: value }))
  }

  
  const handleUpdateSupervisor = async () => {
    try{
      setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))

      const user_privilege_levels = user?.privilege_levels

      if(!user_privilege_levels || user_privilege_levels?.length < 1){
        //navigate to login page

        //alert error
          setInterfaceMessage('snackbar_message', "Please login")
        setInterfaceSeverity('snackbar_severity', "error")
        // toggleInterfaceVisibility('snackbar_visible')
        showSnackbar()

        handleComponentTimeouts(
          'navigateLoginTimeout', 
          setTimeout(() => {
            navigate(CLIENT_LINKS.login.url)
          }, 1000) 
          //set it to the timeout value itself
        )

        return ''
      }
      if(componentTimeouts.navigateLoginTimeout){
        handleComponentTimeouts(
          'navigateLoginTimeout', 
          clearTimeout(componentTimeouts.navigateLoginTimeout) 
        )
        //set it to the cleared timeout value
      }
      const can_update_user = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_update_users_Util, user_privilege_levels )
      
      if(can_update_user){ 
        if(!renderedPromptToDelete){
          setInterfaceMessage('snackbar_message', "This action is permanent. Click again to confirm")
          // handleInterfaceState('snackbar_autohide_duration', '')
          setSnackbarDuration(5000)
          setInterfaceSeverity('snackbar_severity', "warning")
          // toggleInterfaceVisibility('snackbar_visible')
          showSnackbar()
          setRenderedPromptToDelete(true) //confirmation prompt
          
          return ''
        }

        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })
  
        const body = {...componentState}
        console.log('BODY', body)
        const res = await axiosInstance.post(`/supervisor/update_supervisor/${body._id}`, body)

        // const res = await axiosInstance.post(`/users/admin_update_user/bac`, body)

        setInterfaceMessage('snackbar_message', "Successfully updated user")
        setInterfaceSeverity('snackbar_severity', "success")
        // toggleInterfaceVisibility('snackbar_visible')
        showSnackbar()
        console.log('SUCCESS')
        await refetchUser()

        dispatch({
          type: "ADMIN_FORCE_UPDATE"
        })

        setTimeout(() => {
          setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

          handleClose()
          // console.log('This runs once after 2000ms');
        }, 500);

        return ''
      }
      throw new Error("You are not granted permission to perform this action ")

    }
    catch(error){
      //  return <ReusableSnackbarComponent open={true}  inner_message={'Updated successfully'} autoHideDuration={2000}/>
      setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

      setInterfaceMessage('snackbar_message', `Failed to update user. Reason: ${error.response?.data?.message  || 'An error occurred' }`)
      setInterfaceSeverity('snackbar_severity', "error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()


      console.error(error)
      // return alert(error.response.data?.message || 'An error occurred')
    }
    finally{
      // toggleInterfaceVisibility('snackbar_visible')
      // toggleInterfaceVisibility('snackbar_visible', '')
    }
  }


  useEffect(() => {
    if(fetched_user && !isLoadingUser){
      setComponentState( (prev) => ( {...prev, fetched_user}) )
    }
  }, [fetched_user])

  // console.log(interfaceState.button_disabled)
  
  const [renderedPromptToDelete, setRenderedPromptToDelete] = useState(false)
  // console.log(renderedPromptToDelete)
  const handleDeleteSupervisor = async () => {
    try{
      // console.log('hi')
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))

    const user_privilege_levels = user?.privilege_levels

    if(!user_privilege_levels || user_privilege_levels?.length < 1){
      //navigate to login page

      //alert error
        setInterfaceMessage('snackbar_message', "Please login")
      setInterfaceSeverity('snackbar_severity', "error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()

      handleComponentTimeouts(
        'navigateLoginTimeout', 
        setTimeout(() => {
          navigate(CLIENT_LINKS.login.url)
        }, 1000) 
      )

      return ''
    }
    if(componentTimeouts.navigateLoginTimeout){
      handleComponentTimeouts(
        'navigateLoginTimeout', 
        clearTimeout(componentTimeouts.navigateLoginTimeout) 
      )
    }
    const can_delete_user = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_delete_users_Util, user_privilege_levels )
    
    //check if auth user has the privilege
    if(can_delete_user){
      if(!renderedPromptToDelete){
        setInterfaceMessage('snackbar_message', "This action is permanent. Click again to confirm")
        // handleInterfaceState('snackbar_autohide_duration', '')
        setSnackbarDuration(5000)
        setInterfaceSeverity('snackbar_severity', "warning")
        // toggleInterfaceVisibility('snackbar_visible')
        showSnackbar()
        setRenderedPromptToDelete(true) //confirmation prompt
        
        return ''
      }

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      // const response = await axiosInstance.get('/users/find_all');
      const response = await axiosInstance.delete(`/supervisor/delete_supervisor/${passed_supervisor._id}`);
      console.log(response.data.message)
      setInterfaceMessage('snackbar_message', "Supervisor has been permanently deleted")
      // handleInterfaceState('snackbar_autohide_duration', '')
      // setSnackbarDuration(5000)
      setInterfaceSeverity('snackbar_severity', "success")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()

      dispatch({
        type: "ADMIN_FORCE_UPDATE"
      })

      setTimeout(() => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

        handleClose()
        // console.log('This runs once after 2000ms');
      }, 500);
      
      return ''
    }
    //if nothing positive occurred
    //i.e we didnt find the user in useAuth privilege level after array differnetiation
    throw new Error("You are not granted permission to perform this action ")
    }
    catch(error){
      // console.error(error)
      setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

      setInterfaceMessage('snackbar_message', `Failed to delete supervisor. Reason: ${error.response?.data?.message  || 'An error occurred' }`)
      setInterfaceSeverity('snackbar_severity', "error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()
      console.error(error)

    }
    finally{
      setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))

    }
    //else alert error
  }


  useEffect(() => {
    //if any of these change, user must reconfirm deletion
    if(renderedPromptToDelete){
      setRenderedPromptToDelete(false)
    }
  }, [fetched_user, componentState, ])

  useEffect(() => {
    let timer
    //after 3 seconds, if user fails to delete, they will need to reconfirm
    if(renderedPromptToDelete){

      timer = setTimeout(() => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
        setRenderedPromptToDelete(false)

        // console.log('This runs once after 2000ms');
      }, 5000);

    }

    return () => clearTimeout(timer)
  }, [renderedPromptToDelete])



  

  

  //refetch the id  
  return(
    <div className='amap-fullscreen-modal-body'>
      <ReusableSnackbarComponent 
      severity={interfaceState.snackbar_severity}
      open={interfaceState.snackbar_visible}  
      inner_message={interfaceState.snackbar_message} 
      setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
      autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>

      <div className='amap-fullscreen-modal-header'>
        <div className='amap-fullscreen-modal-header-inner'>
          <span className='close'
          onClick={handleClose}
          > <Close fontSize='2rem' /> </span>
          <span className='title'>Update Supervisor</span>
          <span 
          onClick={handleUpdateSupervisor}
          className='update'>SAVE</span>
        </div>
      </div>
      <div className='amap-fullscreen-modal-body-inner'>
        <div className='amap-fullscreen-modal-body-form-section'>
          <div  className='create-thesis-page-form-item'>
            <label>Supervisor email</label>
            <input 
            style={{
              cursor: 'not-allowed'
            }}
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="supervisor_email" 
            defaultValue={passed_supervisor?.supervisor_email || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Supervisor title (Prof, Mrs, Doctor, etc)</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="supervisor_title" 
            defaultValue={passed_supervisor?.supervisor_title || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Supervisor name</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="supervisor_name" 
            defaultValue={passed_supervisor?.supervisor_name || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>About Supervisor</label>
            <textarea
            cols="40"
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-form-item-textarea' type='text' 
            name="supervisor_about" 
            defaultValue={passed_supervisor?.supervisor_about || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Affiliated department</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="department" 
            defaultValue={passed_supervisor?.department || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Affiliated institution</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="institution" 
            defaultValue={passed_supervisor?.institution || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Affiliated institution abbreviation (e.g OAU, UNILORIN, FUOYE)</label>
            <input 
            onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="institution_abbreviation" 
            defaultValue={passed_supervisor?.institution_abbreviation || ''}
            />
          </div>
          <div  className='create-thesis-page-form-item'>
            <details>
              <summary style={{cursor: 'pointer'}}> Delete Section </summary>
              <label style={{
                color: 'tomato'
              }}>Warning: Super admin section!</label>
              <div>
              <button
              onClick={handleDeleteSupervisor}
              disabled={interfaceState.button_disabled}
              style={{
                color: 'white',
                backgroundColor: 'tomato',
                width: '20%'
                // width: 'inherit'
              }}
              >Delete Supervisor</button>
              </div>
            </details>
          </div>
        
        </div>

        <div></div>
      </div>
    </div>
  )
}







const ReusableUserItem = ({passed_supervisor}) => {

  const level_colors = {
    1: 'green',
    2: 'lime',
    3: 'lime',
    50: 'tomato',
    51: 'tomato',
    52: 'tomato',
    53: 'tomato'
  }

  
  const roundButton = ( skin= {text:'', color:'white', bg_color:'gray', size_factor:1} ) => {
    const {text, color, bg_color, size_factor} = skin
    return (
    <div style={{
      backgroundColor: bg_color || 'gray', 
      color: color || 'white',
      lineHeight: `1`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `${size_factor || 1}rem`,
      width: `${size_factor || 1}rem`,
      borderRadius: '50%',
      padding: '0.5rem',
      overflow: 'hidden'
    }}>
      <span style={{
      // backgroundColor: bg_color || 'gray', 
      // color: color || 'white',
      all: 'none',
      lineHeight: `1`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      // width: '100%',
      // backgroundColor:'pink'
      // height: `${size_factor || 1}rem`,
      // width: `${size_factor || 1}rem`,
      // borderRadius: '50%',
    }}>
        {text && text[0].toUpperCase() }
        </span>
  {/* asdfdsafsad */}
      </div>
      )
    }

  const [componentState, setComponentState] = useState({
    is_full_dialog_open: false, 
  })

  const toggleFullDialog = () => {
    setComponentState(prev => ({
      is_full_dialog_open: !prev.is_full_dialog_open
    }))
  }

  const finallyUpdate = async() => {
        const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const resp = await axiosInstance.get('/theses/advanced_search2')
    console.log(resp)
  }

  return(
    <div className='reusable-user-item'>
      <div className='reusable-user-item-inner'>
        <ReusableFullscreenDialog 
        open={componentState.is_full_dialog_open}
        setOpen={toggleFullDialog}
        title={'Update Supervisor'}
        action_title={'update'}
        no_preloaded_content
        // otherFunctionsPassed={finallyUpdate}
        main_body={<FullScreenModalBody 
          passed_supervisor={passed_supervisor}
          open={componentState.is_full_dialog_open}
          setOpen={toggleFullDialog}
          />}
        />
        <div>
          <span>{passed_supervisor?.supervisor_title} {' '}</span>
          <span>{passed_supervisor?.supervisor_name} {' '}</span>
        </div>
        <div>{passed_supervisor?.department}</div>
        <div>{passed_supervisor?.faculty}</div>
        <div>{passed_supervisor?.institution_abbreviation}</div>
        <div>{passed_supervisor?.supervisor_email}</div>
        <div>
          {
          passed_supervisor?.supervisor_name &&
          <> 
            <span> {roundButton( {text:passed_supervisor?.supervisor_name, size_factor:0.8}) }</span>
          </>
          }
        </div>
        <div className='reusable-user-item-buttons'>
          {/* <span className='reusable-user-item-button'>disable user</span>
          <span className='reusable-user-item-button'>verify user</span>
          <span className='reusable-user-item-button'>revoke admin</span>
          <span className='reusable-user-item-button'>delete user</span> */}
          <span 
          onClick={toggleFullDialog}
          className='reusable-user-item-button'>update supervisor</span>
          <span 
          onClick={toggleFullDialog}
          className='reusable-user-item-button'>delete supervisor</span>

        </div>
      </div>
    </div>
  )
}










const AdminManageSupervisors = () => {
  UseHideSidebar()

  const [componentState, setComponentState] = useState({
    isPageSettingsOpened: false,
    isSearchInterfaceOpened: false,
    isCreateNewCategoryInterfaceOpened: false
  })

  const toggleCreateNewDialog = () => {
    setComponentState(prev => ({
      isCreateNewCategoryInterfaceOpened: !prev.isCreateNewCategoryInterfaceOpened
    }))
  }

  const toggleSearchInterface = () => {
    setComponentState(prev => ({
      ...prev,
      isSearchInterfaceOpened: !prev.isSearchInterfaceOpened
    }))
  }

  const togglePageSettingsUI = () => {
    setComponentState(prev => ({
      ...prev,
      isPageSettingsOpened: !prev.isPageSettingsOpened
    }))
  }


  const menu_bar = [
    {
      id: 1,
      text: 'All',
      simplified: 'all',
      query: `&privilege_levels=${authorized_privilege_levels_that_are_admin_Util.join(',')}`
    },

    {
      id: 7,
      text: 'Admin',
      simplified: 'admin',
      query: "&privilege_level=51"
    },
    {
      id: 8,
      text: 'Superadmins',
      simplified: 'superadmins',
      query: "&privilege_level=52"
    },
    {
      id: 9,
      text: 'Owners',
      simplified: 'owners',
      query: "&privilege_level=53"
    },


  ]



  const {admin_results_page_items, admin_force_update, admin_current_page, admin_url_search_string, dispatch} = useAdminContext()

  const [currentItem, setCurrentItem] = useState( menu_bar[0])

  const handleSetCurrentItem = (item) => {
    setCurrentItem(item)
  }



  //when current item changes, i.e tab click /horizonatal scroll, reset the page to 1
  useEffect(() => {
    // first
    dispatch({
      type: 'MODIFY_ADMIN_CURRENT_PAGE',
      payload: 1
    })

  }, [currentItem,])

  const handlePageChange = (newPage) => {
    dispatch({
      type: "MODIFY_ADMIN_CURRENT_PAGE", 
      payload: newPage
    })  
  }

  const [queryState, setQueryState] = useState({
    baseURL: `/users/find_all_users_advanced_search?itemsPerPage`,
    itemsPerPage: admin_results_page_items,
    current_page: admin_current_page,
    sort: 'latest_modified' ,
    searchBy: '',
    input_box_value: ''

  })



  

  // console.log(queryState.searchBy)

  const constructApiUrl = () => {
    const url = `/supervisor/find_supervisor_advanced_search?current_page=${admin_current_page}&itemsPerPage=${admin_results_page_items}&sort=${queryState.sort || ''}&${queryState.searchBy}=${queryState.input_box_value || ''}${currentItem.query}`
    // console.log(url)
    return url
    // return {
    //   //in other to conform to our backend
    //   current_page: admin_current_page,
    //   itemsPerPage: admin_results_page_items,
    //   sort: queryState.sortBy,
    //   [queryState.searchBy] : queryState.input_box_value
    // }
  }
  
  // console.log(constructApiUrl())


  const handleQueryState = (e) => {
    const {name, value} = e.target
    setQueryState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  // console.log(queryState)


  const [searchMode, setSearchMode] = useState(false)

  //now how do i construct the query based on my react ui

  const fetchUsersList = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })


    const response = await axiosInstance.get(`${constructApiUrl()}`);
    // console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_users_list , isLoading: isLoadingAllUsers, refetch:refetchUsersList } = useQuery({
    queryKey: ['allUsersList'],
    // networkMode: 'always',
    queryFn: () =>
      fetchUsersList()
  }
  )

  useEffect(() => {
    // console.log('Hi')
    // console.log(constructApiUrl())
    refetchUsersList()
    // return () => {
    // }
  }, [admin_results_page_items, admin_current_page, admin_force_update, admin_url_search_string, 
    // searchMode, 
    queryState.sort, currentItem])
  
  useEffect(() => {
    // first
    if(fetched_users_list){

      dispatch({
        type: 'SET_ADMIN_MAX_PAGE',
        payload: fetched_users_list?.total_pages || 1
      })
    }
  }, [fetched_users_list])
  

  const handleSearchMode = () => {
    // if
    if(!queryState.input_box_value){
      return alert('please enter a value in the search box')
    }

    if(!queryState.searchBy){
      return alert('please enter a field to search by')
    }

    if(searchMode){
      //means we are reclicking it
      return refetchUsersList()
    }

    //else
    setSearchMode(!searchMode)

  }
  // console.log(queryState)

  useEffect(() => {
  //clear the input box whenever we exit search mode.
  //But retain searchBy so that the user doesnt need to reset it again
    const clearInputWhenSearchModeIsOff = () => {
    //clear the input box and value state
      if(!searchMode){
        setQueryState(prev => ({
          ...prev,
          input_box_value: ''
        }))
        // return setSearchMode(!searchMode)
      }

      dispatch({
       type: "ADMIN_FORCE_UPDATE"
      })
    }
    clearInputWhenSearchModeIsOff()
    // refetchUsersList()

  }, [searchMode])

  useEffect(() => {
    //if we are in search mode and we are changing the input then we can refetch
    //so we can search in real time
      if(searchMode){
        refetchUsersList()
      }
    
  
    }, [queryState.input_box_value, queryState.searchBy])


  



  const searchByOptions = {
    email: 'email',
    supervisor_name: 'name',
    supervisor_title: 'title',
    institution: 'institution',
    institution_abbreviation: 'institution abbreviation' , 
    department: 'department',
    faculty: 'faculty',
  }


  const sortOptions = {
    recent: 'Most Recent',
    oldest: 'Oldest',
    latest_modified: 'Latest Modified',
    oldest_modified: 'Oldest Modified',
    email_asc: 'Email (A-Z)',
    email_desc: 'Email (Z-A)',
    supervisor_name_asc: 'Supervisor name (A-Z)',
    supervisor_name_desc: 'Supervisor name (Z-A)',
    supervisor_title_asc: 'Supervisor title (A-Z)',
    supervisor_title_desc: 'Supervisor title (Z-A)',
    department_asc: 'Department (A-Z)',
    department_desc: 'Department (Z-A)',
    faculty_asc: 'Faculty (A-Z)',
    faculty_desc: 'Faculty (Z-A)',
    institution_asc: 'Institution (A-Z)',
    institution_desc: 'Institution (Z-A)',
    institution_abbreviation_asc: 'Institution abbreviation (A-Z)',
    institution_abbreviation_desc: 'Institution abbreviation (Z-A)',
  };


   


  // console.log(admin_results_page_items, admin_force_update, admin_current_page, admin_url_search_string)

  // const aim = useAuth

  const initialSelected = (state_value, value_passed) => {
    const check_passed = (state_value === value_passed)
    return check_passed
  }

  return (
    <div className='admin-manage-accounts-page'>
      {
        isLoadingAllUsers &&
        <ReusableLoaderInCenter passed_loader={<ReusableLoader3 />} />
      }
      <div 
      style={{
        display: isLoadingAllUsers ? 'none' : 'flex'
      }}
      className='admin-manage-accounts-page-inner'>
        <div>
          <ReusableFullscreenDialog 
          open={componentState.isCreateNewCategoryInterfaceOpened}
          setOpen={toggleCreateNewDialog }
          // title={'Update Account'}
          // action_title={'update'}
          no_preloaded_content
          // otherFunctionsPassed={finallyUpdate}
          main_body={<FullScreenModalAddNewSupervisorBody 
            open={componentState.isCreateNewCategoryInterfaceOpened}
            setOpen={toggleCreateNewDialog}
            />}
          /> 
        </div>
        
        <div 
        className='amap-title-block'>
          <div className='amap-reusable-title-block-inner'>
              <div className='amap-reusable-title-block-left'>
                <span className='amap-reusable-title-block-left-text'>Admin Manage  Supervisors</span>
                <span className='amap-reusable-title-block-left-icon'> <ManageAccountsOutlined fontSize='1rem' /> </span>
              </div>
          </div>
        </div>
        <div className='amap-horizontal-scroll-menu'>
          <ReusableHorizontalScrollMenu 
          array={menu_bar}
          currentItem={currentItem}
          handleSetCurrentItem={handleSetCurrentItem}  />
        </div>

        <div className='amap-search-functionality'> 
          {
          !componentState.isSearchInterfaceOpened &&
          <div className='amap-search-functionality-inner'>
            <span style={{
              cursor: 'pointer'
            }} onClick={toggleSearchInterface}> <Search fontSize='2rem' /> </span>
          </div>
          }
          {
          componentState.isSearchInterfaceOpened &&
          <div className='amap-search-functionality-inner'>  
            <span style={{
              cursor: 'pointer'
            }} onClick={toggleSearchInterface}> <Search fontSize='2rem' /> </span>
            <span className='input-container'>
              <input 
              name='input_box_value'
              onChange={handleQueryState}
              value={queryState.input_box_value}
              className='input-container-input'
              type='text' 
              title='enter your search parameter' placeholder='enter your search parameter' />
            </span>
            <span  className='search-by-select-span'>
              <select
                  className='search-by-select'
                  id='sort-by'
                  name='searchBy'
                  onChange={handleQueryState}
                  value={queryState.searchBy}
                  // value={filtersState.sortBy}
                  // onChange={handleSortByChange}
                >
                  <option value='' key={""}>-- Search by --</option>
                  {
                  // note that we passed, key ie object key, instead of object property/value
                  Object.entries(searchByOptions).map(([value, label]) => (
                  <option key={value} value={value} >
                      {label}
                  </option>
                  ))}
              </select>
            </span>        
            {/* <span> */}
              <button 
              className='search-button'
              style={{
                // border: 'none'
              }}
              // onClick={()=>setSearchMode(true)}
              onClick={handleSearchMode}
              title="search">Search</button>
            {/* </span> */}
            <ReusableSnackbarComponent 
            // positioning={{vertical: 'top', horizontal: 'right'}}
            positioning={{vertical: 'top', horizontal: 'center'}}
            inner_message={"You are in search mode"} 
            open={searchMode} 
            setOpen={setSearchMode} 
            // otherFunctionsPassed={otherFunctions}
            />
            <span 
            // onClick={handleSearchMode}
            onClick={()=>setSearchMode(!searchMode)}
            style={{
              display: searchMode ? 'block' : 'none',
              color: searchMode ? 'red' : 'inherit',
              cursor: 'pointer'
            }}
            className='exit-search-button'>Exit search</span>
          </div>
          }
        </div>
        <div className='amcp-add-new-category'>
          <button onClick={toggleCreateNewDialog}>Add a new supervisor</button>
          <span onClick={toggleCreateNewDialog}> <AddOutlined fontSize='1rem' /> </span>
        </div>
        <div className='user-items'>
          {
          fetched_users_list?.supervisors?.length > 0 &&
          fetched_users_list?.supervisors?.map((supervisor, index) => (
            <ReusableUserItem passed_supervisor={supervisor} key={index} />
          ))
          }
          {
          fetched_users_list?.supervisors?.length < 1 &&
          <div 
          style={{
            paddingLeft: '1rem'
          }}
          >No results</div>
          }
        </div>
        <div className='amap-pagination-component'>
          <div className='amap-pagination-component-inner'>
            <ReusablePaginationComponent 
            currentPage={admin_current_page} 
            totalPages={fetched_users_list?.total_pages } 
            onChange={handlePageChange}/>
            <div className='amappc-bottom-right'>
              <span>
                <select
                  className='sort-results-by-select'
                  id='sort-by'
                  name='sort'
                  onChange={handleQueryState}
                  // defaultValue={sortOptions.mo}
                  // value={filtersState.sortBy}
                  // onChange={handleSortByChange}
                >
                  <option value='' key={""}>-- Sort results by --</option>
                  {
                  // note that we passed, key ie object key, instead of object property/value
                  Object.entries(sortOptions).map(([value, label]) => (
                  <option 
                   selected={initialSelected(queryState.sort, value)}
                   key={value} value={value}>
                      {label}
                  </option>
                  ))}
                </select>
              </span>
              <span
              onClick={togglePageSettingsUI}
              className='amappc-bottom-right-settings-icon-container'
              >
                <Settings fontSize='1rem' />
              </span>
              <ReusableDraggableDialog  
              header={"Page Settings"}
              main_body={
                <ReusablePageSettingsDialogAdmin
                  page_context={useAdminContext()} 
                  setIsModalOpened={togglePageSettingsUI}
                  isModalOpened={componentState.isPageSettingsOpened}
                />
              } 
              isOpened={componentState.isPageSettingsOpened}
              setIsOpened={togglePageSettingsUI}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AdminManageSupervisors